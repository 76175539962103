import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import ValueInput from "../forms/ValueInput";
import DescField from "../forms/DescField";
import FileUpload from "../forms/FileUpload";
import { Formik } from "formik";
import SelectField from "../forms/SelectField";
import getImageBase64DataUri from "src/utils/getImageBase64DataUri";
import { TEMPLATE_SUPPORTED_IMAGE_FORMATS } from "src/constants/template";

const YesNoOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const TemplateEditForm = ({
  initialValues,
  validationSchema,
  onSubmit,
  validate,
}) => {
  const [imageFormatError, setImageFormatError] = useState(null);

  const handleFileUpload = (e, setFieldValue) => {
    let file = e.target.files[0];
    let fileFormat = file.type;

    if (!TEMPLATE_SUPPORTED_IMAGE_FORMATS.includes(fileFormat)) {
      setImageFormatError(
        "Image could not be uploaded, not supported Image format. Format supported is ( image/svg )"
      );
    } else {
      setImageFormatError(null);
      getImageBase64DataUri(file, (dataUri) => {
        const objectUrl = URL.createObjectURL(file);
        setFieldValue("previewImage", objectUrl);
        setFieldValue("templateImage", dataUri);
      });
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "md",
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          handleBlur,
        }) => (
          <Box
            style={{
              display: "flex",
              gap: ".5rem",
              flexDirection: "column",
            }}
          >
            <Box
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  maxWidth: 200,
                  my: 3,
                  "& img": {
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                {values?.previewImage ? (
                  <img src={values?.previewImage} alt='preview' />
                ) : null}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: ".25rem",
                  }}
                >
                  <FileUpload
                    required
                    name='templateImage'
                    accept={TEMPLATE_SUPPORTED_IMAGE_FORMATS.join(",")}
                    onImageChange={(e) => {
                      handleFileUpload(e, setFieldValue);
                    }}
                  />
                  <Box>
                    <Typography color='#313131' fontSize='16px'>
                      Upload Template
                    </Typography>
                    {imageFormatError && (
                      <Typography
                        color='#ff0000'
                        variant='body2'
                        style={{
                          width: "70%",
                        }}
                      >
                        {imageFormatError}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <form autoComplete='off' onSubmit={handleSubmit}>
              <Box>
                <ValueInput
                  variant='outlined'
                  label='Title'
                  placeholder='Enter Title'
                  name='title'
                  value={values?.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.title && errors.title}
                  helperText={touched.title && errors.title}
                />
              </Box>
              <Box>
                <DescField
                  variant='outlined'
                  placeholder='Enter Description'
                  label='Description'
                  name='description'
                  value={values?.description}
                  multiline
                  rows={4}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.description && errors.description}
                  helperText={touched.description && errors.description}
                />
              </Box>
              <Box
                style={{
                  marginBottom: 24,
                }}
              >
                <ValueInput
                  style={{
                    marginBottom: 0,
                  }}
                  variant='outlined'
                  placeholder='Enter Tags, seperated by comma (,)'
                  label='Tags'
                  name='tags'
                  value={values?.tags?.join(",")?.trim()}
                  onChange={(e) => {
                    const { target } = e;
                    const { value, name } = target ?? {};

                    handleChange({
                      target: {
                        name,
                        value: value.split(","),
                      },
                    });
                  }}
                  onBlur={handleBlur}
                  error={touched.description && errors.tags}
                  helperText={touched.description && errors.tags}
                />
                {values?.tags?.length > 0 && (
                  <Typography>{JSON.stringify(values?.tags)}</Typography>
                )}
              </Box>
              <Box>
                <ValueInput
                  variant='outlined'
                  placeholder='Enter Sort Postion'
                  label='Sort Position'
                  type='number'
                  name='sortPosition'
                  value={values?.sortPosition}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.sortPosition && errors.sortPosition}
                  helperText={touched.sortPosition && errors.sortPosition}
                />
              </Box>
              <Box>
                <SelectField
                  labelId='template-isNsfw-select-label'
                  value={values?.isNsfw}
                  label='Template Not Safe For Work'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name='isNsfw'
                  options={YesNoOptions}
                  error={touched.isNsfw && errors.isNsfw}
                  helperText={touched.isNsfw && errors.isNsfw}
                />
              </Box>
              <Box>
                <SelectField
                  labelId='public-template-select-label'
                  value={values?.isPublic}
                  label='Public Template'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name='isPublic'
                  options={YesNoOptions}
                  error={touched.isPublic && errors.isPublic}
                  helperText={touched.isPublic && errors.isPublic}
                />
              </Box>
              <Box>
                <SelectField
                  labelId='has-face-template-select-label'
                  value={values?.hasFace}
                  label='Has Face Template'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name='hasFace'
                  options={YesNoOptions}
                  error={touched.hasFace && errors.hasFace}
                  helperText={touched.hasFace && errors.hasFace}
                />
              </Box>
              <LoadingButton
                size='large'
                type='submit'
                variant='contained'
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Update Template
              </LoadingButton>
            </form>
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default TemplateEditForm;
