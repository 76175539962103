import { useEffect, useState } from "react";
import { useQueries, useQuery } from "react-query";
import { getData } from "src/utils/getData";
import { PropTypes } from "prop-types";
import moment from "moment";

import { TableCell, TableRow, IconButton, Box, Tooltip } from "@mui/material";
import { Edit, DeleteForever } from "@mui/icons-material";

import YMTableDesktop from "../common/YMTableDesktop";
import NoDataFound from "../NoDataFound";
import UserInfoCellComponent from "./UserInfoCellComponent";
import Loading from '../Loading';
import { useModalDispatch } from "src/contexts/GlobalModalContext";


const tableConstants = () => {
  return [
    {
      title: 'User',
      key: 'user',
    },
    {
      title: 'Admin',
      key: 'admin',
    },
    {
      title: 'Start Date',
      key: 'startDate',
    },
    {
      title: 'End Date',
      key: 'endDate',
    },
    {
      title: 'Comments',
      key: 'comments',
    },
    {
        title: 'Action',
        key: 'action',
    }
  ];
};

const UserTable = (props) => {

    const dispatch = useModalDispatch();

    const [ offset, setOffset ] = useState(0);
    const [ limit, setLimit ] = useState(10);
    const [ page, setPage ] = useState(1);

    const [adminList, setAdminList] = useState([]);
    const [adminMap, updateAdminMap] = useState(new Map());

    useEffect(() => {
      if (page === 1 && offset === 0) return;
      setOffset((page - 1) * limit);
    }, [page]);

    const { isLoading, data, error } = useQuery(['banuser', offset, limit], () => getData(`/user?onlyBanned=true&offset=${offset}&limit=${limit}`));

    useEffect(() => {
      if (data && data.data.length > 0) {
        data.data.map((ban) => {
          setAdminList([...adminList, ban.banMetadata.bannedBy]);
        });
      }
    }, [data]);

    const adminQuery = useQueries(adminList.map(adminId => {
      return {
        queryKey: ['users', adminId],
        queryFn: () => getData(`/user?id=${adminId}`),
        enabled: adminList.length > 0,
        onSuccess: (data) => updateAdminMap(adminMap.set(adminId, data.data[0]))
      }
    }));

    const adminIsLoading = adminQuery.filter((result) => result.isLoading).length !== 0;

    return (
        <YMTableDesktop cols={tableConstants()} count={(data) ? Number(data.count) : 0} page={page - 1} limit={limit} handlePageChange={(page) => setPage(Number(page))} handleLimitChange={(limit) => setLimit(Number(limit))} >
            {
              (data)
              ? (data?.count > 0 && !adminIsLoading) 
                ? data?.data.map((ban, index) => (
                  <TableRow key={index} hover>
                    <TableCell>
                      <UserInfoCellComponent imageURL={ban?.avatar?.urlOriginal} username={ban?.username} displayName={ban?.displayName} />
                    </TableCell>
                    <TableCell>
                      <UserInfoCellComponent image={adminMap.get(ban?.banMetadata.bannedBy)?.avatar?.urlOriginal} username={adminMap.get(ban?.banMetadata.bannedBy)?.username} displayName={adminMap.get(ban?.banMetadata.bannedBy)?.displayName} />
                    </TableCell>
                    <TableCell>
                      { moment(ban?.banMetadata.startDate * 1000).format('DD/MM/YYYY') }<br />
                      { moment(ban?.banMetadata.startDate * 1000).format('HH:MM:SS') }
                    </TableCell>
                    <TableCell>
                      { moment(ban?.banMetadata.endDate * 1000).format('DD/MM/YYYY') }<br />
                      { moment(ban?.banMetadata.endDate * 1000).format('HH:MM:SS') }
                    </TableCell>
                    <TableCell>
                      {ban?.banMetadata.comments}
                    </TableCell>
                    <TableCell>
                      <Box display="flex" width="100px" justifyContent="space-between">
                        <Tooltip title="Edit Ban">
                          <IconButton variant="outlined"  color="warning" size="large" onClick={() => dispatch({ type: 'UPDATE_USER_BAN', value: ban })}><Edit /></IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Ban">
                          <IconButton variant="contained" color="error" size="large" onClick={() => dispatch({ type: 'UNBAN_USER', value: ban })}><DeleteForever /></IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
                : <TableRow>
                    <TableCell colSpan={tableConstants().length} >
                      <NoDataFound />
                    </TableCell>
                  </TableRow>
              : <TableRow>
                  <TableCell colSpan={tableConstants().length} >
                    {
                      (isLoading || adminIsLoading)
                        ? <Loading />
                        : <NoDataFound error={error} />
                    }
                  </TableCell>
                </TableRow>
            }
        </YMTableDesktop>
    )
}

UserTable.propTypes = {
    data: PropTypes.array,
}

export default UserTable;
