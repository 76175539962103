import { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { useQueries, useQuery } from "react-query";
import { useNavigate } from 'react-router-dom';
import { getData } from "src/utils/getData";
import moment from "moment";

import { TableCell, TableRow, IconButton, Box, Avatar, Typography, Tooltip, Chip, CircularProgress } from "@mui/material";
import { DeleteForever, Edit, Visibility } from "@mui/icons-material";

import YMTableDesktop from "../common/YMTableDesktop";
import NoDataFound from "../NoDataFound";
import Loading from "../Loading";
import { useModalDispatch } from "src/contexts/GlobalModalContext";

const tableConstants = () => {
  return [
    {
      title: 'Name',
      key: 'categoryName',
    },
    {
      title: 'Description',
      key: 'categoryDescription',
    },
    {
      title: 'Tags',
      key: 'categoryTags',
    },
    {
      title: 'Total Posts',
      key: 'nPosts',
    },
    {
      title: 'Created',
      key: 'createdAt',
    },
    {
        title: 'Action',
        key: 'action',
    }
  ];
};

const CategoryTable = () => {

    const dispatch = useModalDispatch();

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [categories, setCategories] = useState([]);
    const postCount = new Map();

    useEffect(() => {
      if (page === 1 && offset === 0) return;
      setOffset((page - 1) * limit);
    }, [limit, offset, page]);

    const { isLoading, data, error } = useQuery(['category', offset, limit], () => getData(`/category?offset=${offset}&limit=${100}`));

    useEffect(() => {
      if (data && data.data && data.data.length > 0) {
        setCategories(data.data);
      }
    }, [data])

    const getPostCountByCategoryId = (categoryId) => getData(`/post?categoryId=${categoryId}&limit=1&offset=0&type=public`);

    const postResults = useQueries(categories.map((category) => {
      return {
        queryKey: ['posts', 'category', category.id],
        queryFn: () => getPostCountByCategoryId(category.id),
        enabled: !!(categories.length > 0),
        refetchOnMount: false,
      }
    }));

    const isLoadingPostCount = postResults.filter((result) => result.isLoading).length !== 0;

    if (!isLoadingPostCount) {
      postResults.forEach((result) => {
        if (result.data.length > 0) {
          postCount.set(result.data[0].category.id, result.data.count)
        }
      })
    }

    const navigate = useNavigate();

    return (
        <YMTableDesktop cols={tableConstants()} count={(data) ? Number(data.count): 0} page={page - 1} limit={limit} handlePageChange={(page) => setPage(Number(page))} handleLimitChange={(limit) => setLimit(Number(limit))}>
            {
              (categories && categories.length > 0)
              ? categories.map((category, index) => (
                <TableRow key={index} hover>
                  <TableCell width="20%">
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <Avatar
                        src={category.image.urlOriginal}
                        sx={{ mr: 2 }}
                        />
                        <Typography
                        color="textPrimary"
                        variant="body1"
                        >
                            { category.name.toUpperCase() }
                        </Typography>
                    </Box>
                  </TableCell>
                  <TableCell width="25%">
                    {category.description}
                  </TableCell >
                  <TableCell width="20%">
                    {
                      category.tags.map((tag, index) => (
                        <Chip key={index} label={tag.toUpperCase()} variant="outlined" sx={{
                          margin: 1
                        }}/>
                      ))
                    }  
                  </TableCell>
                  <TableCell width="10%" sx={{
                    textAlign: 'center'
                  }}>
                    {
                      (isLoadingPostCount)
                      ? <CircularProgress size={20} />
                      : 
                        <Typography color="textPrimary" variant="h6">
                          {
                            postCount.get(category.id)
                          }
                        </Typography>
                    }
                  </TableCell>
                  <TableCell width="10%">
                    { moment(category.createdAt).format('DD/MM/YYYY') }
                  </TableCell>
                  <TableCell width="15%">
                    <Tooltip title="View Category on YouMeme">
                      <IconButton variant="outlined" color="primary" size="large" href={`${process.env.REACT_APP_YOUMEME_FRONTEND_URL}/categories/${category.name.toLowerCase()}`} target="_blank">
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Category">
                      <IconButton variant="outlined"  color="warning" size="large" onClick={() => navigate(`/dashboard/categoryandtags/category/edit/${category.id}`, { state: category }) }>
                        <Edit/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Category">
                      <IconButton variant="outlined" color="error" size="large" onClick={() => dispatch({ type: 'DELETE_CATEGORY', value: category })}>
                        <DeleteForever />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
              : <TableRow>
                  <TableCell colSpan={tableConstants().length} >
                    {
                      (isLoading)
                        ? <Loading />
                        : <NoDataFound error={error} />
                    }
                  </TableCell>
                </TableRow>
            }
        </YMTableDesktop>
    )
}

CategoryTable.propTypes = {
    data: PropTypes.array,
}

export default CategoryTable;
