import {
  Navigate,
  Route,
  Routes,
  useRoutes,
  Redirect,
  Outlet,
  useLocation,
} from "react-router-dom";
import { Fragment } from "react";
import DashboardLayout from "../layouts/dashboard";

import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import Dashboard from "../pages/Dashboard";

import Login from "../pages/Login";
import VerifyLogin from "../pages/VerifyLogin";
import AllUser from "../pages/User";
import BannedUser from "../pages/BannedUser";
import Posts from "../pages/Posts";
import Agents from "../pages/Agents";
import Events from "../pages/Events";
import Category from "../pages/Category";
import Page404 from "../pages/Page404";
import Maintenance from "src/pages/Maintenance";
import Activity from "src/pages/Activity";
import Tags from "src/pages/Tags";
import Sponsor from "src/pages/Sponsor";
import { useAuthState } from "src/contexts/UserAuthContext";
import MemeCompetition from "src/pages/MemeCompetition";
import Submissions from "src/pages/Submissions";
import RefreshToken from "src/pages/RefreshToken";
import { Roles } from "src/_helpers/role";
import sidebarConfig from "src/layouts/dashboard/SidebarConfig";

import News from "src/pages/News";
import TemplateList from "src/pages/Template/List";
import TemplateEdit from "src/pages/Template/Edit";
import TemplateCreate from "src/pages/Template/Create";
import StickerList from "src/pages/Sticker/List";
import StickerCreate from "src/pages/Sticker/Create";
import StickerEdit from "src/pages/Sticker/Edit";

const ProtectedRouter = () => {
  const { userLoggedIn, sessionExpiry } = useAuthState();

  return (
    <>
      <Routes>
        <Route path='/' element={<LogoOnlyLayout />}>
          <Route path='' element={<Navigate to='login' />} />
          <Route path='login' element={<Login />} />
          <Route path='complete-login' element={<VerifyLogin />} />
          <Route path='refresh_token' element={<RefreshToken />} />
          <Route path='404' element={<Page404 />} />
          <Route path='*' element={<Navigate to='404' />} />
        </Route>
        <Route
          path='dashboard'
          element={
            <CustomRouteWrapper
              isUser={userLoggedIn}
              sessionExpiry={sessionExpiry}
            />
          }
        >
          <Route element={<DashboardLayout />}>
            <Route
              element={
                <RoleProtectedRoutes
                  roles={[Roles.SUPER_ADMIN, Roles.ADMIN, Roles.ANALYST]}
                />
              }
            >
              <Route path='' element={<Navigate to='home' />} />
              <Route path='home' element={<Dashboard />} />
            </Route>
            <Route
              element={
                <RoleProtectedRoutes roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
              }
            >
              <Route path='users/all' element={<AllUser />} />
              <Route path='users/userban' element={<BannedUser />} />
            </Route>
            <Route
              element={
                <RoleProtectedRoutes
                  roles={[
                    Roles.SUPER_ADMIN,
                    Roles.ADMIN,
                    Roles.MODERATOR,
                    Roles.ANALYST,
                  ]}
                />
              }
            >
              <Route path='posts' element={<Posts />} />
            </Route>
            <Route
              element={
                <RoleProtectedRoutes roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
              }
            >
              <Route path='agents' element={<Agents />} />
              <Route
                path='agents/new'
                element={<Agents loadFormComponent={true} />}
              />
              <Route
                path='agents/edit'
                element={<Agents loadFormComponent={true} />}
              />
            </Route>
            <Route
              element={
                <RoleProtectedRoutes
                  roles={[Roles.SUPER_ADMIN, Roles.ADMIN, Roles.EVENT_MANAGER]}
                />
              }
            >
              <Route path='events/main' element={<Events />} />
              <Route
                element={
                  <RoleProtectedRoutes
                    roles={[Roles.SUPER_ADMIN, Roles.ADMIN]}
                  />
                }
              >
                <Route
                  path='events/main/new'
                  element={<Events loadFormComponent={true} />}
                />
              </Route>
              <Route
                path='events/main/edit/:eventId'
                element={<Events loadFormComponent={true} />}
              />
              <Route
                path='events/memecompetition'
                element={<MemeCompetition />}
              />
              <Route
                element={
                  <RoleProtectedRoutes
                    roles={[Roles.SUPER_ADMIN, Roles.ADMIN]}
                  />
                }
              >
                <Route
                  path='events/memecompetition/new'
                  element={<MemeCompetition loadFormComponent={true} />}
                />
              </Route>
              <Route
                path='events/memecompetition/edit/:eventId'
                element={<MemeCompetition loadFormComponent={true} />}
              />
              <Route
                path='events/memecompetition/:eventId/submissions'
                element={<Submissions />}
              />
            </Route>
            <Route
              path='templates'
              element={
                <RoleProtectedRoutes roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
              }
            >
              <Route index element={<TemplateList />} />
              <Route path='create' element={<TemplateCreate />} />
              <Route path='edit/:templateId' element={<TemplateEdit />} />
            </Route>
            <Route
              path='stickers'
              element={
                <RoleProtectedRoutes roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
              }
            >
              <Route index element={<StickerList />} />
              <Route path='create' element={<StickerCreate />} />
              <Route path='edit/:stickerId' element={<StickerEdit />} />
            </Route>
            <Route
              element={
                <RoleProtectedRoutes roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
              }
            >
              <Route path='categoryandtags/category' element={<Category />} />
              <Route path='categoryandtags/tags' element={<Tags />} />
              <Route
                path='categoryandtags/category/new'
                element={<Category loadFormComponent={true} />}
              />
              <Route
                path='categoryandtags/category/edit/:categoryid'
                element={<Category loadFormComponent={true} />}
              />
            </Route>
            <Route
              element={
                <RoleProtectedRoutes roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
              }
            >
              <Route path='sponsor' element={<Sponsor />} />
            </Route>
            <Route
              element={
                <RoleProtectedRoutes
                  roles={[Roles.SUPER_ADMIN, Roles.ADMIN, Roles.NEWS_MODERATOR]}
                />
              }
            >
              <Route path='news' element={<News />} />
              <Route
                path='news/new'
                element={<News loadFormComponent={true} />}
              />
              <Route
                path='news/edit/:id'
                element={<News loadFormComponent={true} />}
              />
            </Route>
            <Route
              element={
                <RoleProtectedRoutes roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
              }
            >
              <Route path='maintenance' element={<Maintenance />} />
            </Route>
            <Route
              element={
                <RoleProtectedRoutes roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
              }
            >
              <Route path='activity' element={<Activity />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
};

const CustomRouteWrapper = ({ isUser, sessionExpiry, ...props }) => {
  const location = useLocation();
  const isSession = new Date().getTime() < sessionExpiry;
  if (isUser && isSession) return <Outlet />;
  if (isUser && !isSession)
    return <Navigate to={"/refresh_token"} replace state={{ location }} />;
  else return <Navigate to={`/login`} replace state={{ location }} />;
};

const RoleProtectedRoutes = ({ roles }) => {
  const { user } = useAuthState();

  if (roles.includes(user.role)) {
    return <Outlet />;
  }

  return <Navigate to='/404' />;
};

export default ProtectedRouter;
