import { useState, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query'
import { useLocation ,useNavigate } from 'react-router-dom'
import { Box, TextField, Autocomplete, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/system';

import DescField from './DescField';
import FileUpload from './FileUpload';
import diff from 'src/utils/getObjectDiff'
import { getData } from "src/utils/getData";
import { useGlobalMutation } from 'src/utils/useGlobalMutations';
import { addCategory, editCategory, uploadCategoryImage } from 'src/api/query';
import { useAlertDispatch } from 'src/contexts/GlobalAlertContext'

const TagInput = styled(TextField)(
    () => ({
      color: '#313131',
      backgroundColor: 'white',
      width: '100%',
      borderRadius: 5,
      marginBottom: 24,
      fontSize: 16,
      resize: 'none',
      outline: 'none',
      fontFamily: 'Poppins',
    })
);

const CategoryForm = () => {

    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "image/webp"
    ];

    //Calls the action that will perform the add Category action and update the category list
    const addCategoryAction = useGlobalMutation(addCategory);

    //Calls the action that will perform the edit Category action and update the category list
    const editCategoryAction = useGlobalMutation(editCategory);

    //Calls the action that will perform the upload image and update the category image
    const uploadCategoryImageAction = useGlobalMutation(uploadCategoryImage);

    //get the dispatch actions for each kind of alert
    const {successAlert, errorAlert} = useAlertDispatch();

    const queryClient = useQueryClient();

    const navigate = useNavigate();
    const location = useLocation();

    const { data } = useQuery('category', () => getData(`/category`));

    const [categoryData, setCategoryData] = useState({
        name: null,
        description: null
    })
    const [selectedTags, setSelectedTags] = useState([]);
    const [sourceImage, setSourceImage] = useState({
        previewImage: null,
        raw: null
    });
    const [sourceImageFormat, setSourceImageFormat] = useState(null);
    const [errors, setErrors] = useState({
        name: null,
        tags: null,
        imageSize: null,
        imageFormat: null
    });
    const [isSubmitting, toggleIsSubmitting] = useState(false);

    const isCreateMode = !location.state;

    useEffect(() => {
        if(!isCreateMode){
            {/*TODO: Build up image from API response - APIURL/image/urlImage.sourceImageFormat*/}
            setSourceImage(prev => ({...prev, previewImage: location.state.image.urlOriginal}));
            //setSourceImageFormat(bla bla bla)
            setSelectedTags(location.state.tags);
            setCategoryData({
                name: location.state.name,
                description: location.state.description
            });
        }
        
    },[])

    const isFormValid = () => {
        if(isCreateMode){
            return categoryData.name && categoryData.description && selectedTags && sourceImage.raw && errors.name === null && errors.tags === null && errors.imageSize === null && errors.imageFormat === null;
        }
        else{
            return categoryData.name && categoryData.description && selectedTags  && errors.name === null && errors.tags === null;
        }
    }


    const validateImageFormat = (type) => {
        const isValid = SUPPORTED_FORMATS.includes(type);
         isValid ? setErrors(prev => ({...prev, imageFormat: null})) : setErrors(prev => ({...prev, imageFormat: "Image format is not supported"}));
         return isValid;
    }

    const validateImageSize = (size) => {
        const approvedSize = 3000000; //size in bytes
        let isValid = false;
        
        if(size < approvedSize){
          setErrors(prev => ({...prev, imageSize: null}));
          isValid = true;
        }
        else{
          setErrors(prev => ({...prev, imageSize: "Image is too large!"}));
        }
        return isValid;
    }
    
    const validateImage = (file) => {
        const isSizeValid = validateImageSize(file.size);
        const isFormatValid = validateImageFormat(file.type);

        return isSizeValid && isFormatValid;
    }

    const validate = (name, value) => {
        switch(name){
            case 'name':
                const namePattern = /^([^0-9\s]*)$/;
                const isValid = namePattern.test(value);
                isValid ? setErrors(prev => ({...prev, name: null})) : setErrors(prev => ({...prev, name: "Name should not contain numbers or spaces"}));
            break;
            
            case 'tags':
                value.length > 5 || value.length < 1 ? setErrors(prev => ({...prev, tags: "You can only select a min of 1 and a max of 5 tags"})) : setErrors(prev => ({...prev, tags: null}));
            break;

            default:
                break;
        }
    }

    const handleChange = (e) => {
        setCategoryData({ ...categoryData, [e.target.name]: e.target.value });
        validate(e.target.name, e.target.value)
    };

    const handleTagSearch = (e, value) => {
        setSelectedTags(value);
        validate('tags' ,value);
    };

    const handleFileUpload = (e) => {
        let file = e.target.files[0];
        let format = file.type.replace('image/', '');
        let fileValid = validateImage(file);
        setSourceImage({
            previewImage: URL.createObjectURL(file),
            raw: file
        });
        setSourceImageFormat(format)

        //sends image to server if in edit mode and valid
        if(!isCreateMode && fileValid){
            toggleIsSubmitting(true);
            uploadCategoryImageAction.mutate({
	            id: location.state.id,
                image:{
                    type: format,
                    size: file.size
                },
	            data: file,
            }, {
                onSuccess: () => {
                    successAlert(`Category Image Set!`);
                    toggleIsSubmitting(false);
                },
                onError: (error) => {
                    errorAlert(error.message);
                    toggleIsSubmitting(false);
                }
            })
        }
    }

    const preSubmit = (values) => {
        let initialValues = { name: location.state.name, tags: location.state.tags, description: location.state.description};
        let finalValues = values;

        finalValues = diff(initialValues, values);
        return finalValues;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        toggleIsSubmitting(true);
        const formData = new FormData(e.currentTarget);
        formData.append('tags', JSON.stringify(selectedTags));

        if(isCreateMode){
            formData.append('sourceImageFormat', sourceImageFormat);
            formData.append('source', sourceImage.raw);

            addCategoryAction.mutate(formData, {
                onSuccess: () => {
                    successAlert(`Category Added!`);
                    toggleIsSubmitting(false);
                    queryClient.invalidateQueries('category')
                    navigate("/dashboard/categoryandtags/category")
                },
                onError: (error) => {
                    errorAlert(error.message)
                    toggleIsSubmitting(false);
                }
            })
        }
        else{
            const editedData = preSubmit({ name: categoryData.name, tags: selectedTags, description: categoryData.description});
            editCategoryAction.mutate({
                id: location.state.id,
                data: editedData
            }, {
                onSuccess: (data) => {
                    successAlert(`Category Updated!`);
                    toggleIsSubmitting(false);
                    queryClient.setQueryData(['category', { id: location.state.id }], data);
                    navigate("/dashboard/categoryandtags/category");
                },
                onError: (error) => {
                    errorAlert(error.message);
                    toggleIsSubmitting(false);
                }
            })
        }
    }

    return (
        <Box sx={{
            maxWidth: 'md'
        }}>   
            <Box
                sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 3
                }}
            >
                <FileUpload
                required
                name="source"
                value={(location.state && location.state.source) || sourceImage}
                accept={SUPPORTED_FORMATS.join(', ')}
                onImageChange={handleFileUpload}
                />
                <Box
                sx={{
                    ml: 5
                }}
                >
                    <Typography
                        color="#313131"
                        fontSize="16px"
                    >
                        Upload Thumbnail
                    </Typography>
                    <Typography
                        color="#828282"
                        fontSize="14px"
                    >
                        80px &times; 80px at Max 3MB
                    </Typography>

                    <Typography color="#ff0000">
                        { errors.imageSize && errors.imageSize }
                    </Typography>
                    <Typography color="#ff0000">
                        {errors.imageFormat && errors.imageFormat}
                    </Typography>
                
                </Box>
            </Box>
            <Box sx={{
                maxWidth: 200,
                my: 3,
                '& img': {
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%'
                    }
            }}>
                {sourceImage ? <img src={sourceImage.previewImage} /> : null}
            </Box>
            <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Box>
                    <TagInput
                        required
                        variant="outlined"
                        placeholder="Enter a name for category"
                        name="name"
                        value={categoryData.name}
                        onChange={handleChange}
                        error={errors.name && Boolean(errors.name)}
                        helperText={errors.name}
                    />
                </Box>
                <Box>
                    <Autocomplete
                        multiple
                        options={[].map((option) => option)}
                        freeSolo
                        name="tags"
                        value={selectedTags}
                        onChange={handleTagSearch}
                        renderInput={(params) => (
                            <TagInput
                                {...params}
                                variant="outlined"
                                placeholder="Select the tag(s) associated to this category"
                                error={Boolean(errors.tags && errors.tags)}
                                helperText={errors.tags}
                            />
                        )}
                    />
                </Box>
                <Box>
                    <DescField
                        placeholder="Description (1000 characters max)"
                        type="text"
                        name="description"
                        value={categoryData.description}
                        onChange={handleChange}
                    />
                </Box>
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={!isFormValid()}
                    loading={isSubmitting}
                >
                    {isCreateMode ? "Add Category" : "Edit Category"}
                </LoadingButton>
            </form>
            
        </Box>
    )
}
export default CategoryForm;
