import { useState, useEffect } from 'react';

import Graph from "../graph/Graph";
import Filter from '../common/Filter';
import { FlexBox, DescText } from '../common/Styled';

import { getYearsForFilter, getMonthsForFilter } from '../../utils/getFilters';
import { useTheme } from '@emotion/react';
import { useQueries } from 'react-query';
import { getUsersByDateRange, getPostsByDateRange } from 'src/api/stats';

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();

const AnnualCountBarChart = () => {
    
    // Get filter options
    const filterOptions = getYearsForFilter();
    
    // Set init data
    const [year, setYear] = useState(filterOptions.items[0].value);
    const [dateRanges, setDateRanges] = useState([]);

    let data = {};

    useEffect(() => {
      let months;
      let dates = [];
      if (year === currentYear) {
        months = getMonthsForFilter(currentMonth + 1);
      } else {
        months = getMonthsForFilter(12);
      }
      for (let month in months) {
        let startDate = (new Date(year, months[month] - 1)).getTime();
        let endDate = (new Date(year, months[month])).getTime();
        let range = {
          startDate,
          endDate
        };
        dates.push(range);
      }
      setDateRanges(dates);
    }, [year])

    // Style the graph
    const theme = useTheme();
    const options = {
        animation: false,
        layout: { padding: 0 },
        legend: {
          display: true,
          position: 'bottom',
          align: 'start',
          labels: {
            usePointStyle: true,
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          xAxes: [
            {
              barThickness: 20,
              maxBarThickness: 15,
              ticks: {
                fontColor: theme.palette.text.secondary,
              },
              gridLines: {
                display: false,
                drawBorder: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                fontColor: theme.palette.text.secondary,
                beginAtZero: true,
                min: 0,
              },
              gridLines: {
                borderDash: [2],
                borderDashOffset: [2],
                color: theme.palette.divider,
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
                zeroLineColor: theme.palette.divider
              }
            }
          ]
        },
        tooltips: {
          backgroundColor: theme.palette.background.paper,
          bodyFontColor: theme.palette.text.secondary,
          borderColor: theme.palette.divider,
          borderWidth: 1,
          enabled: true,
          footerFontColor: theme.palette.text.secondary,
          intersect: false,
          mode: 'index',
          titleFontColor: theme.palette.text.primary,
        },
    };

    const userResults = useQueries(dateRanges.map((range) => {
      return {
        queryKey: ['users', range.startDate, range.endDate],
        queryFn: () => getUsersByDateRange(range.startDate, range.endDate),
        enabled: !!(dateRanges.length > 0)
      }
    }));

    const postResults = useQueries(dateRanges.map((range) => {
      return {
        queryKey: ['posts', range.startDate, range.endDate],
        queryFn: () => getPostsByDateRange(range.startDate, range.endDate),
        enabled: !!(dateRanges.length > 0)
      }
    }));

    const isLoading = userResults.filter((result) => result.isLoading).length !== 0 && postResults.filter((result) => result.isLoading).length !== 0;
    
    if (!isLoading) {
      data = {
        ...data,
        labels: labels.slice(0, currentYear === year ? currentMonth + 1 : 12),
        datasets: [
          {
            label: 'Users',
            data: userResults?.map(result => result.data?.count),
            backgroundColor: '#816AD6',
          },
          {
            label: 'Memes',
            data: postResults.map(result => result.data?.count),
            backgroundColor: '#26C6DA',
          }
        ]
      }
    }
    
    return (
        <>
            <FlexBox>
                <DescText>Annual statistics of new users and published media content </DescText>
                <Filter { ...filterOptions } handleChange={setYear} />
            </FlexBox>
            <Graph graph="bar" data={data} options={options} loading={isLoading} />
        </>
    )
}

export default AnnualCountBarChart;