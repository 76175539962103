import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom'
import {
    Box,
    Select,
    MenuItem,
    InputAdornment
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ValueInput from './ValueInput';
import { updateUserRole } from 'src/api/query';
import { useGlobalMutation } from 'src/utils/useGlobalMutations';
import { useAlertDispatch } from 'src/contexts/GlobalAlertContext'
import Button from 'src/theme/overrides/Button';
import useUserSearch from 'src/utils/useUserSearch';
import { Check, Search } from '@mui/icons-material';


const AgentForm = (props) => {

    const addAgentAction = useGlobalMutation(updateUserRole);

    //get the dispatch actions for each kind of alert
    const {successAlert, errorAlert} = useAlertDispatch();

    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const path = useLocation();

    const AgentSchema = Yup.object().shape({
        email: Yup.string('Enter agent email.').email('Enter a valid email').required('Email is required'),
        role: Yup.string('Select agent role.').required("Please assign a role."),
        id: Yup.string('Search for a user').uuid('User id must be a uuid'),
    });

    const [isAgent, updateIsAgent] = useState(false);
    const [userId, setUserId] = useState('');
    const [searchQuery, updateSearchQuery] = useState('');
    
    const roles = [
        {
          value: 'superadmin',
          label: 'Super Admin'
        },
        {
          value: 'admin',
          label: 'Admin'
        },
        {
          value: 'moderator',
          label: 'Moderator'
        },
        {
          value: 'analyst',
          label: 'Analyst'
        },
        {
            value: 'event',
            label: 'Event Manager',
        },
        {
            value: 'news',
            label: 'News Moderator',
        }
    ];

    const formik = useFormik({
        initialValues: {
            email: undefined,
            role: undefined,
            id: undefined,
            ...path.state,
            ...props
        },
        validationSchema: AgentSchema,
        onSubmit: () => {

            const body = {
                email: values.email,
                role: values.role,
                id: userId
            }

            addAgentAction.mutate(body, {
                onSuccess: () => {
                    successAlert(`User role updated!`);
                    queryClient.invalidateQueries('users');
                    queryClient.invalidateQueries('agents');
                    navigate('/dashboard/agents');
                },
                onError: (error) => {
                    errorAlert(error.message);
                    setSubmitting(false);
                }
            })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, setSubmitting, getFieldProps } = formik;

    const searchResult = useUserSearch(searchQuery);

    useEffect(() => {
        if (searchResult.data && searchResult.data.data && searchResult.data.data.length > 0) {
            updateIsAgent(true);
            setUserId(searchResult.data.data[0].id);
            return;
        }

        if (searchResult.error) {
            errorAlert('Unable to find user at the moment.');
        }
    }, [searchResult]);

    const handleKeyDown = (e) => {
        if (e.code === 'Enter') updateSearchQuery(values.email);
    }

    return (
        <Box sx={{
            maxWidth: 'md'
        }}>   
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Box sx={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                        <ValueInput
                            label="Agent Email"
                            type="email"
                            placeholder="Enter agents email address"
                            name="email"
                            value={values.email}
                            {...getFieldProps('email')}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                            onKeyDown={(e) => handleKeyDown(e)}
                            InputProps={{
                                endAdornment: 
                                    (!isAgent) 
                                        ? <InputAdornment position='end'><Search onClick={() => updateSearchQuery(values.email)} sx={{ cursor: 'pointer' }} /></InputAdornment>
                                        : <InputAdornment position='end'><Check /></InputAdornment>
                            }}
                        />
                    </Box>
                    <Box>
                        <Select
                            variant="outlined"
                            label="Agent Role"
                            name='role'
                            disabled={!isAgent}
                            {...getFieldProps('role')}
                            sx={{
                                width: 'calc(50% - 10px)',
                                padding: '0 20px',
                                marginBottom: '20px',
                                height: 45,
                                fontSize: 16,
                            }}
                            >
                            <MenuItem value="">
                                <em>Select role</em>
                            </MenuItem>
                            {roles.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    {
                        (isAgent) 
                            ? <LoadingButton
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    {(path.state) ? 'Update Agent' : 'Add Agent'}
                                </LoadingButton>
                            : ''
                    }
                </Form>
            </FormikProvider>
        </Box>
    )

}

export default AgentForm;