import { Box, Container, Typography, Divider, Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import Page from "src/components/Page";
import TemplatesTable from "src/components/templates/TemplatesTable";

const TemplateList = () => {
  const { pathname } = useLocation();

  return (
    <Page title='Manage Templates | YM Admin'>
      <Container maxWidth='xl'>
        <Box>
          <Typography color='textPrimary' variant='h2'>
            Manage Templates
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0.5,
          }}
        >
          <Typography color='textSecondary' variant='body2'>
            {pathname}
          </Typography>
          <Link to='/dashboard/templates/create'>
            <Button
              variant='contained'
              color='primary'
              sx={{
                marginBottom: 1,
              }}
            >
              ADD TEMPLATE
            </Button>
          </Link>
        </Box>
        <Divider />
        <TemplatesTable />
      </Container>
    </Page>
  );
};

export default TemplateList;
