import { Box, Container, Typography, Divider, Button } from '@mui/material';
import { Link } from 'react-router-dom';

import Page from '../components/Page';
import EventsTable from '../components/events/EventsTable';
import EventForm from '../components/forms/EventsForm';
import { useAuthState } from 'src/contexts/UserAuthContext';
import { Roles } from 'src/_helpers/role';

const Events = ({ loadFormComponent }) => {

    const { user } = useAuthState();

    return (
        <Page title="Simple Events | YM Admin">
            <Container maxWidth="xl">
                <Box>
                    <Typography
                        color="textPrimary"
                        variant="h2"
                    >
                        Simple Events
                    </Typography>
                </Box>
                <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 0.5
                }}>
                    <Typography
                        color="textSecondary"
                        variant="body2"
                    >
                        {
                            (loadFormComponent)
                            ? "dashboard/events/new"
                            : "dashboad/events"
                        }
                    </Typography>
                    {
                        (loadFormComponent)
                        ? <Link to='/dashboard/events/main'>
                            <Button variant="contained" color="primary" sx={{
                                marginBottom: 1
                            }}>GO BACK</Button>
                          </Link>
                        : (user.role === Roles.EVENT_MANAGER)
                            ? ''
                            :
                                <Link to='/dashboard/events/main/new'>
                                    <Button variant="contained" color="primary" sx={{
                                        marginBottom: 1
                                    }}>ADD EVENT</Button>
                                </Link>
                    }
                </Box>
                <Divider />
                <Box sx={{ pt: 3 }}>
                    {
                        (loadFormComponent)
                        ? <EventForm />
                        : <EventsTable />
                    }
                </Box>
            </Container>
        </Page>
    );
}

export default Events;