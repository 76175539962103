import { Box, Container, Typography, Divider, Button } from "@mui/material";
import { useQueryClient } from "react-query";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Page from "src/components/Page";
import TemplateCreateForm from "src/components/templates/TemplateCreateForm";
import { useGlobalMutation } from "src/utils/useGlobalMutations";
import { createTemplate } from "src/api/query/template";
import { useAlertDispatch } from "src/contexts/GlobalAlertContext";

const TemplateCreate = () => {
  const { templateId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Calls the action that will perform the edit event action and update the event list
  const createTemplateAction = useGlobalMutation(createTemplate);

  // Dispatch actions for each alert
  const { successAlert, errorAlert } = useAlertDispatch();

  const queryClient = useQueryClient();

  // prepare my formik data
  const initialValues = {
    title: "",
    description: "",
    tags: [],
    isNsfw: false,
    isPublic: true,
    hasFace: false,
    sortPosition: "",
    templateImage: "",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(30, "Too long!").required("Required"),
    description: Yup.string().required("Required"),
    tags: Yup.array().max(3, "Maximum of 3 tags allowed"),
    sortPosition: Yup.number().required("Required"),
    isNsfw: Yup.boolean().required("Required"),
    isPublic: Yup.boolean().required("Required"),
    hasFace: Yup.boolean().required("Required"),
    templateImage: Yup.string().required("Required"),
  });

  const onSubmit = (props, { setSubmitting, resetForm }) => {
    delete props.previewImage;
    const newData = {
      ...props,
      tags: props?.tags?.filter((item) => item),
    };
    createTemplateAction.mutate(
      {
        data: newData,
      },
      {
        onSuccess: (data) => {
          const { data: newData } = data;
          successAlert(`Template Created`);
          queryClient.setQueryData(["templates", { id: newData?.id }], newData);
          setSubmitting(false);
          resetForm(false);
        },
        onError: (error) => {
          errorAlert(error.message);
          setSubmitting(false);
        },
      }
    );
  };

  return (
    <Page title='Simple Events | YM Admin'>
      <Container maxWidth='xl'>
        <Box>
          <Typography color='textPrimary' variant='h2'>
            Create Template
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0.5,
          }}
        >
          <Typography color='textSecondary' variant='body2'>
            {pathname}
          </Typography>

          <Link to='/dashboard/templates'>
            <Button
              variant='contained'
              color='primary'
              sx={{
                marginBottom: 1,
              }}
            >
              GO BACK
            </Button>
          </Link>
        </Box>
        <Divider />
        <Box sx={{ pt: 3 }}>
          <TemplateCreateForm
            templateId={templateId}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default TemplateCreate;
