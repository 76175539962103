const { useState, useEffect } = require("react");
const { useQuery } = require("react-query");
const { getData } = require("src/utils/getData");

export const useCategory = () => {
    
    const [categoryList, setCategoryList] = useState([]);
    const [categoryMap, updateCategoryMap] = useState(new Map());

    // TODO: Add support for limit and offset 
    // or fetch and all store all categories in a loop.
    // ONLY when category list grows massively.

    // TODO: Add mutation
    // TODO: Include post counts
    
    const { data, error, isLoading } = useQuery('category', () => getData(`/category?offset=0&limit=100`), {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (data && data.data && data.data.length > 0) {
            setCategoryList([...categoryList ,...data.data]);
            data.data.map((each) => {
                updateCategoryMap(categoryMap.set(each.id, each))
            })
        }
    }, [data]);

    const getCategoryById = (id) => {
        return categoryMap.get(id);
    }

    return {
        categoryList,
        getCategoryById,
        error,
        isLoading,
    }
}