import { Box, Container, Typography, Divider, Button } from "@mui/material";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Page from "src/components/Page";
import StickerCreateForm from "src/components/stickers/StickerCreateForm";
import { useGlobalMutation } from "src/utils/useGlobalMutations";
import { useAlertDispatch } from "src/contexts/GlobalAlertContext";
import { createSticker } from "src/api/query/sticker";

const StickerCreate = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Calls the action that will perform the edit event action and update the event list
  const createStickerAction = useGlobalMutation(createSticker);

  // Dispatch actions for each alert
  const { successAlert, errorAlert } = useAlertDispatch();

  const queryClient = useQueryClient();

  // prepare my formik data
  const initialValues = {
    title: "",
    description: "",
    tags: ["youmeme"],
    isNsfw: false,
    isPublic: true,
    sortPosition: "",
    image: "",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(20, "Too long!").required("Required"),
    description: Yup.string().required("Required"),
    sortPosition: Yup.number().required("Required"),
    image: Yup.string().required("Required"),
  });

  const onSubmit = (props, { setSubmitting, resetForm }) => {
    delete props.previewImage;
    createStickerAction.mutate(
      {
        data: props,
      },
      {
        onSuccess: (data) => {
          const { data: newData } = data;
          successAlert(`Sticker Created`);
          queryClient.setQueryData(["stickers", { id: newData?.id }], newData);
          setSubmitting(false);
          resetForm(false);
        },
        onError: (error) => {
          errorAlert(error.message);
          setSubmitting(false);
        },
      }
    );
  };

  return (
    <Page title='Simple Events | YM Admin'>
      <Container maxWidth='xl'>
        <Box>
          <Typography color='textPrimary' variant='h2'>
            Create Sticker
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0.5,
          }}
        >
          <Typography color='textSecondary' variant='body2'>
            {pathname}
          </Typography>
          <Button
            variant='contained'
            color='primary'
            sx={{
              marginBottom: 1,
            }}
            onClick={() => navigate(-1)}
          >
            GO BACK
          </Button>
        </Box>
        <Divider />
        <Box sx={{ pt: 3 }}>
          <StickerCreateForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default StickerCreate;
