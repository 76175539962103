import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Avatar, Box, Typography, Stack } from "@mui/material";

import getInitials from "src/utils/getInitials";
import PostRanking from "../events/PostRanking";

const ModalInfoCell = ({ modalprops, type }) => {
  const [modalInfo, setModalInfo] = useState({
    maintext: "",
    subtext: "",
    imageurl: "",
  });
  const [modalComponent, setModalComponent] = useState({ state: null });

  useEffect(() => {
    switch (type) {
      case "user":
        setModalInfo({
          ...modalInfo,
          maintext: modalprops.displayName,
          subtext: modalprops.username,
          imageurl: modalprops.avatar?.urlOriginal,
        });
        //Change Image URL to real prop later
        break;
      case "post":
        setModalInfo({
          ...modalInfo,
          maintext: modalprops.title,
          subtext: modalprops.category?.name,
          imageurl: modalprops.meme?.renderedImage?.urlOriginal,
        });
        //When not using fakers for post category use --> modalprops.user.displayName;
        //When not using fakers for post category add  --> modalprops.meme.renderedImage.urlOriginal;
        break;
      case "event":
        setModalInfo({
          ...modalInfo,
          maintext: modalprops.name,
          subtext: modalprops.urlWebsite,
          imageurl: modalprops.fkImageId,
        });
        //When not using fakers for image use --> modalprops.image.urlOriginal;
        break;
      case "agent":
        setModalInfo({
          ...modalInfo,
          maintext: modalprops.displayName,
          subtext: modalprops.username,
          imageurl: modalprops.avatar?.urlOriginal,
        });
        break;
      case "submission":
        setModalComponent({ ...modalInfo, state: modalprops });
        break;
      case "category":
        setModalInfo({
          ...modalInfo,
          maintext: modalprops.name.toUpperCase(),
          subtext: modalprops.id,
          imageurl: modalprops.image.urlOriginal,
        });
        break;
      default:
        setModalInfo({ ...modalInfo, ...modalprops });
    }
  }, [type, modalprops]);

  if (type === "submission") {
    return <PostRanking state={modalprops} />;
  }

  return (
    <Box sx={{ alignItems: "center", display: "flex" }}>
      {type == "user" || type == "agent" ? (
        <Avatar src={modalInfo.imageurl} sx={{ mr: 2 }} alt='User Avatar'>
          {getInitials(modalInfo.maintext)}
        </Avatar>
      ) : (
        <Avatar
          src={modalInfo.imageurl}
          sx={{ mr: 2 }}
          variant='rounded'
          alt='Preview'
        >
          {getInitials(modalInfo.maintext)}
        </Avatar>
      )}
      <Stack>
        <Typography color='text.primary' variant='body1'>
          {modalInfo.maintext}
        </Typography>
        <Typography color='text.secondary' variant='body3'>
          {modalInfo.subtext}
        </Typography>
      </Stack>
    </Box>
  );
};

ModalInfoCell.propTypes = {
  type: PropTypes.string.isRequired,
  modalprops: PropTypes.object.isRequired,
};
export default ModalInfoCell;
