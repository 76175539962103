const { useState, useEffect } = require("react")
const { useQuery } = require("react-query");
const { getData } = require("./getData");

const validateEmail = (email) => {
    return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

const useUserSearch = ( searchQuery ) => {

    const [searchParam, setSearchParam] = useState(null);
    const [searchType, setSearchType] = useState(null);
    const [searchResult, setSearchResult] = useState({
        data: null,
        error: null
    });

    useEffect(() => {
        if (searchQuery === '' || searchQuery === null || searchQuery === undefined) return;
        let type = searchQuery.slice(0,1);
        switch (type) {
            case '@':
                setSearchType('username');
                setSearchParam(searchQuery.slice(1));
                break;
            case '#':
                setSearchType('displayName');
                setSearchParam(searchQuery.slice(1));
                break;
            case ':':
                setSearchType('ethAddress');
                setSearchParam(searchQuery.slice(1));
                break;
            default:
                setSearchType('email');
                if (validateEmail(searchQuery)) {
                    setSearchParam(searchQuery);
                }
                break;
        }
    }, [searchQuery]);
    
    useEffect(() => {
        if (searchParam === null || searchParam === '' || searchType === null) return;
        getData(`/user?${searchType}=${searchParam}`)
        .then((response) => {
            setSearchResult({ ...searchResult, data: response, error: null });
        })
        .catch((error) => {
            setSearchResult({ ...searchResult, data: null, error: error });
        })
    }, [searchParam, searchType])

    return searchResult;
}

export default useUserSearch;