import React, { useRef } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

import UploadFileIcon from "../../assets/images/upload.svg";

const UploadForm = styled("input")(() => ({
  position: "absolute",
  zIndex: -100,
}));

const FileUpload = ({ accept, onImageChange, height = 85 }) => {
  const fileInputField = useRef(null);

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  return (
    <>
      <Box
        sx={{
          border: "1px dashed #816AD6",
          borderRadius: 1,
          backgroundColor: "#F7F5FF",
          height,
          width: 415,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            display: "flex",
            "& img": {
              objectFit: "none",
            },
          }}
          onClick={handleUploadBtnClick}
        >
          <img src={UploadFileIcon} alt='uploadfile' />
        </Box>
        <UploadForm
          name='source'
          type='file'
          ref={fileInputField}
          onChange={onImageChange}
          accept={accept}
        />
      </Box>
    </>
  );
};

export default FileUpload;
