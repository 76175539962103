import { getAuthData, getSelfUserId } from "./self";
import { getAllCategories } from "./category";
import { getAllEvents } from "./events";
import { getAllUsers } from "./user";
import { localAxios } from "src/api/axios";

// If ymca.js is imported from the main App.js/App.ts, it attaches a
// YMCA object to window, which can help debug data fetching from the
// backend.

const YMCA = {
    util: {
        localAxios
    },
    self: {
        getAuthData,
        getSelfUserId
    },
    category: {
        getAllCategories
    },
    users: {
        getAllUsers
    },
    events: {
        getAllEvents
    }
}

window.ymca = YMCA;