import { useEffect } from "react";
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import Loading from '../components/Loading';
import { useAuthState, useAuthDispatch } from 'src/contexts/UserAuthContext';

const RefreshToken = () => {

    const { refreshToken } = useAuthDispatch();
    const { loginError, loginMessage, sessionToken, sessionExpiry, userLoggedIn } = useAuthState();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        refreshToken(sessionToken);
    }, []);

    useEffect(() => {
        // Do nothing if session token is expired else navigate
        // to location.state or home
        const isSession = sessionExpiry > new Date().getTime();
        if (userLoggedIn && isSession) {
            navigate('/dashboard/home', { replace: true })
        }
    }, [sessionExpiry]);

    useEffect(() => {
        if (loginError) setTimeout(() => navigate(`/login`), 5000);
    }, [loginError]);

    return (
        <>
            <Box sx={{
                marginTop: '150px'
            }}>
                <Loading />
                <Typography sx={{
                    textAlign: 'center',
                    marginTop: '20px',
                }}>
                    {
                        (loginError)
                        ? `Error: ${loginError}`
                        : (loginMessage)
                            ? `Success: ${loginMessage}`
                            : 'Refreshing token and creating session....'
                    }
                </Typography>
            </Box>
        </>
    )
}

export default RefreshToken;