import { useRef } from "react";
import { exportComponentAsPNG } from "react-component-export-image";

import Page from "../components/Page";
import DataCard from "../components/dashboard/DataCard";

import {
  Box,
  Container,
  Typography,
  Button,
  Divider,
  Grid,
} from "@mui/material";

import usersImg from "../assets/images/users.svg";
import downIcon from "../assets/images/down.svg";
import memesImg from "../assets/images/memes.svg";
import adsImg from "../assets/images/ads.svg";
import { styled } from "@mui/system";
import AnnualCountBarChart from "src/components/dashboard/AnnualCountBarChart";
import HourlyCountLineChart from "src/components/dashboard/HourlyCountLineChart";

const DownloadBtn = styled(Button)(() => ({
  textTransform: "none",
  color: "#313131",
  fontSize: 13,
  "& img": {
    marginRight: 10,
    marginBottom: 5,
  },
}));

const staticDataForCard = [
  {
    title: "Total Users",
    color: "#FC725A",
    type: "users",
    img: usersImg,
  },
  {
    title: "Total Posts",
    color: "#CB80FC",
    type: "memes",
    img: memesImg,
  },
  {
    title: "Total Events",
    color: "#26C6DA",
    type: "events",
    img: adsImg,
  },
];

const Dashboard = () => {
  const componentRef = useRef();

  return (
    <Page title='Dashboard | YM Admin'>
      <Container maxWidth='xl'>
        <Box ref={componentRef}>
          <Typography color='textPrimary' variant='h2'>
            Dashboard
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0.5,
          }}
        >
          <Typography color='textSecondary' variant='body2'>
            dashboard/
          </Typography>
          <DownloadBtn onClick={() => exportComponentAsPNG(componentRef)}>
            <img src={downIcon} alt='download' />
            Download Report
          </DownloadBtn>
        </Box>
        <Divider />
        <Grid container spacing={3} marginTop={0} marginBottom='30px'>
          {staticDataForCard.map((item) => (
            <Grid item lg={4} md={4} sm={6} xl={4} xs={12} key={item.title}>
              <DataCard
                color={item.color}
                title={item.title}
                type={item.type}
                img={item.img}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={3} marginTop={0} marginBottom='30px'>
          <Grid item lg={6} xs={12}>
            <AnnualCountBarChart />
          </Grid>

          <Grid item lg={6} xs={12}>
            <HourlyCountLineChart />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
