import { getData } from "src/utils/getData";
import { localAxios } from "../axios";

const ROUTE_NAME = "/sticker";
/**
 * @type {Map<string, string>}
 */
const imageMimeTypes = new Map();
imageMimeTypes.set("png", "image/png");
imageMimeTypes.set("jpeg", "image/jpeg");
imageMimeTypes.set("jpg", "image/jpeg");

/**
 * @description Delete Sticker Query
 * @param {string} id of the sticker to be deleted
 * @returns {Promise}
 */
export const deleteSticker = async (payload) => {
  return localAxios.delete(`${ROUTE_NAME}/${payload.id}`);
};

/**
 * @description Delete Sticker Query
 * @param {string} id of the sticker to be deleted
 * @returns {Promise}
 */
export const getSticker = async (templateId) => {
  return getData(`${ROUTE_NAME}?id=${templateId}`);
};

/**
 * @description Edit Sticker
 * @param {String} payload.title
 * @param {String} payload.description
 * @param {Number} payload.sortPosition
 * @param {Boolean} payload.isPublic
 * @param {Boolean} payload.isNsfw
 * @param {Array<String>} payload.tags
 */
export const editSticker = async (payload) => {
  return localAxios.patch(`${ROUTE_NAME}/${payload.id}`, payload.data);
};

/**
 * @description Create Template
 * @param {String} payload.title
 * @param {String} payload.description
 * @param {Number} payload.sortPosition
 * @param {Boolean} payload.isPublic
 * @param {Boolean} payload.isNsfw
 * @param {Array<String>} payload.tags
 * @param {String} payload.templateImage
 */
export const createSticker = async (payload) => {
  return localAxios.post(ROUTE_NAME, payload.data);
};
