/* eslint-disable no-param-reassign */
import axios from 'axios';

/**
 * fetchAccessTokenFromStore fetches the user's access token form the browser's
 * local storage and returns it, if the token was found.
 * If the token was not found, or if there was a formatting issue, it returns
 * empty string instead of throwing an error.
 * @returns {string}
 */
 function fetchAuthTokenFromStore() {
  const userStoreEntry = localStorage.getItem('user');
  if (!userStoreEntry) return ''
  /**
   * @type {{accessToken: string} | null}
   */
  let parsedUserObject = null
  try {
      parsedUserObject = JSON.parse(userStoreEntry)
  } catch (err) {
      return ''
  }
  let accessToken = parsedUserObject.accessToken
  if (!accessToken) accessToken = ''
  return accessToken
}

const http = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    'Authorization': `Bearer ${fetchAuthTokenFromStore()}`
  },
  responseType: 'json',
});


http.interceptors.request.use(
  (config) => {
    if(localStorage.getItem('user')){
      config.headers.Authorization = "Bearer " + JSON.parse(localStorage.getItem('user')).accessToken;

    }
    return config
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);
export const localAxios = http;

export default http;