import * as Yup from 'yup';
import { useFormik }  from 'formik';
import { useQueryClient } from 'react-query'
import {
    Button,
    DialogActions,
    MenuItem,
    TextField
} from '@mui/material';

import { useModalState, useModalDispatch } from 'src/contexts/GlobalModalContext';
import { useAlertDispatch } from 'src/contexts/GlobalAlertContext'
import { useGlobalMutation } from 'src/utils/useGlobalMutations';
import { banUser } from 'src/api/query';


const BanUserForm = () => {

    const BanSchema = Yup.object().shape({
        user_id: Yup.string().required('Please select a user'),
        duration: Yup.number().required('Please select duration'),
        comments: Yup.string().min(5).required('Please add comments for deleting the user'),
    });

    const { modalTypeDefaults, modalProps } = useModalState();
    const dispatch = useModalDispatch();

    //Calls the action that will perform the ban action and update the user
    const submitAction = useGlobalMutation(banUser);

    const queryClient = useQueryClient();

    const {successAlert, errorAlert} = useAlertDispatch();

    const formik = useFormik({
        initialValues: { 
            user_id: modalProps.id, 
            duration: '', 
            comments: '' 
        },
        validationSchema: BanSchema,
        onSubmit: (values) => {
            submitAction.mutate(values, {
                onSuccess: () => {
                    successAlert(`User Banned!`);
                    queryClient.invalidateQueries('users');
                    queryClient.invalidateQueries('banuser');
                    dispatch({ type: 'HIDE_MODAL' });
                },
                onError: (error) => {
                    errorAlert(error.message);
                    setSubmitting(false);
                }
            });
        }
      });

    const {handleSubmit, handleChange, handleBlur, errors, touched, values, dirty, isValid, setSubmitting } = formik;

    const period = [
        {
          value: 2 * 7 * 24 * 60 * 60,
          label: '2 Weeks'
        },
        {
          value: 30 * 24 * 60 * 60,
          label: '1 Month'
        },
        {
          value: 2 * 30 * 24 * 60 * 60,
          label: '2 Months'
        },
        {
          value: 86 * 365 * 24 * 60 * 60,
          label: 'Permanent'
        }
    ];

    return (
        <form autoComplete="off" onSubmit={handleSubmit}>
            
            <TextField
                variant="outlined"
                name='duration'
                select
                displayEmpty
                value={values.duration}
                inputProps={{ 'aria-label': 'Duration'}}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.duration && Boolean(errors.duration)}
                helperText={touched.duration && errors.duration}
                fullWidth
                sx={{
                    padding: '0 20px',
                    color: '#313131',
                    backgroundColor: 'white',
                    marginBottom: '40px',
                    height: 45,
                    fontSize: 16,
                }}
            >
                <MenuItem disabled value="">
                    <em>Select Duration</em>
                </MenuItem>
                {period.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>

            <TextField
                multiline
                rows={4}
                variant="outlined"
                name="comments"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.comments}
                error={Boolean(touched.comments && errors.comments)}
                helperText={touched.comments && errors.comments}
                sx={{
                    padding: '0 20px',
                    marginBottom: '20px'
                }}
            />

            <DialogActions>
            <Button variant="outlined" color="primary" onClick={() => dispatch({ type: 'HIDE_MODAL' })}>Cancel</Button>
            <Button type="submit" variant="contained"  color="warning" disabled={!dirty || !isValid}>Submit</Button>
            </DialogActions>
        </form>
    )
}

export default BanUserForm;