import axios from 'axios'
import { parseJWT } from 'src/utils/parseJwt';

const api_baseUrl = process.env.REACT_APP_API_ENDPOINT

export const authActions = (dispatch) => ({
    logIn: async (email) => {
        axios
            .post(
                `${api_baseUrl}/auth/send-email/?target=admin`,
                JSON.stringify({ email: email}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                      }
                }
            )
            .then((response) => {
                if (response && response.data) {
                    dispatch({ type: 'LOGIN_INITIATED', value: response.data.message });
                } else {
                    dispatch({ type: 'SET_USER_LOGIN_ERROR', value: 'Unable to authenticate user.' });
                }
            })
            .catch((error) => {
                dispatch({ type: 'SET_USER_LOGIN_ERROR', value: error.message });
            });
    },
    verifyLogin: async (token) => {
        axios
            .post(
                `${api_baseUrl}/auth/complete-login`,
                JSON.stringify({ token: token }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                      }
                }
            )
            .then((response) => {
                if (response.data && response.data.sessionToken) {
                    localStorage.setItem('user', JSON.stringify({
                        sessionToken: response.data.sessionToken,
                        accessToken: response.data.accessToken,
                        user: response.data.user,
                        sessionExpiry: parseJWT(response.data.sessionToken).exp * 1000,
                    }));
                    dispatch({ type: 'USER_LOGGED_IN', value: response.data });
                } else {
                    dispatch({ type: 'SET_USER_LOGIN_ERROR', value: 'Token verification failed, please try again.' });
                }
            })
            .catch((error) => {
                if (error.response) {
                    localStorage.clear();
                    window.location = process.env.REACT_APP_URL;
                }
                dispatch({ type: 'SET_USER_LOGIN_ERROR', value: error.message });
            });
    },
    handleLoginError: (error) => {
        dispatch({ type: 'SET_USER_LOGIN_ERROR', value: error });
    },
    logOut: () => {
        localStorage.clear();
        dispatch({ type: 'USER_LOGOUT' });
        window.location = process.env.REACT_APP_URL;
    },
    refreshToken: (sessionToken) => {
        axios
            .post(
                `${api_baseUrl}/auth/refresh-access-token`,
                JSON.stringify({ sessionToken: sessionToken }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                      }
                } 
            )
            .then((response) => {
                if (response && response.data) {
                    let existingUser = JSON.parse(localStorage.getItem('user'));
                    existingUser = {
                        ...existingUser,
                        sessionToken: response.data.sessionToken,
                        accessToken: response.data.accessToken,
                        sessionExpiry: parseJWT(response.data.sessionToken).exp * 1000,
                    }
                    localStorage.setItem('user', JSON.stringify(existingUser));
                    dispatch({ type: 'TOKENS_REFRESHED', value: { accessToken: response.data.accessToken, sessionToken: response.data.sessionToken, message: `Successfully refreshed token.`  } });
                } else {
                    localStorage.clear();
                    dispatch({ type: 'SET_USER_LOGIN_ERROR', value: 'Token verification failed, please try again.' });
                }
            })
            .catch((error) => {
                localStorage.clear();
                dispatch({ type: 'SET_USER_LOGIN_ERROR', value: 'Token verification failed, please log in.' });
            }) 
    }
});