import { useState } from 'react';
import { Box, Container, Typography, Divider } from '@mui/material';

import Page from '../components/Page';
import Filter from '../components/common/Filter';
import PostsTable from 'src/components/posts/PostsTable';
import { getDaysForFilter, daysSinceToday } from 'src/utils/getFilters';

const Posts = () => {
    // const filterOptions = getDaysForFilter();
    // const [days, setDays] = useState(1);
    // const handleFilter = (value) => {
    //     setDays(daysSinceToday(value));
    // }

    return (
        <Page title="Posts | YM Admin">
            <Container maxWidth="xl">
                <Box>
                    <Typography
                        color="textPrimary"
                        variant="h2"
                    >
                        Posts
                    </Typography>
                </Box>
                <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 0.5
                }}>
                    <Typography
                        color="textSecondary"
                        variant="body2"
                    >
                        dashboard/posts
                    </Typography>
                    {/* <Filter { ...filterOptions } handleChange={handleFilter} /> */}
                </Box>
                <Divider />
                <Box sx={{ pt: 3 }}>
                    <PostsTable />
                </Box>
            </Container>
        </Page>
    );
}

export default Posts;