import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAuthState } from 'src/contexts/UserAuthContext';

const AccountInfo = () => {

    const theme = useTheme();
    const { user } = useAuthState();

    return (
        <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle1" sx={{ color: 'black' }} noWrap>
                {user.username}
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.primary.main }} noWrap>
                {user.email}
            </Typography>
        </Box>
    )
}

export default AccountInfo;