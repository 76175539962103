import { Avatar, TableCell, TableRow, Box, Tooltip, IconButton, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { useAlertDispatch } from "src/contexts/GlobalAlertContext";
import { useModalDispatch } from "src/contexts/GlobalModalContext";
import { getData } from "src/utils/getData";
import YMTableDesktop from "../common/YMTableDesktop";
import Loading from "../Loading";
import NoDataFound from "../NoDataFound";
import UserInfoCellComponent from "../user/UserInfoCellComponent";
import moment from "moment";
import { DeleteForever, Edit, Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Roles } from "src/_helpers/role";
import { useAuthState } from "src/contexts/UserAuthContext";

const tableConstants = () => {
    return [
        {
            title: 'News Item',
            key: 'newsItem',
        },
        {
            title: 'Total Comments',
            key: 'totalComments',
        },
        {
            title: 'Total Reactions',
            key: 'totalReactions',
        },
        {
            title: 'Created By',
            key: 'createdBy',
        },
        {
            title: 'Date',
            key: 'createdAt',
        },
        {
            title: 'Actions',
            key: 'action',
        }
    ];
};

const NewsTable = () => {

    const dispatch = useModalDispatch();
    const navigate = useNavigate();
    const { user } = useAuthState();

    const [ page, setPage ] = useState(1);
    const [ offset, setOffset ] = useState(0);
    const [ limit, setLimit ] = useState(10);
    const [ news, setNews ] = useState([]);

    useEffect(() => {
        if (page === 1 && offset === 0) return;
        setOffset((page - 1) * limit);
      }, [page]);  

    const fetchData = () => {
        if (user && user.role === Roles.NEWS_MODERATOR) return getData(`/post?offset=${offset}&limit=${limit}&sort=latest&type=news&userId=${user.id}`)
        else return getData(`/post?offset=${offset}&limit=${limit}&sort=latest&type=news`)
    }

    const { isLoading, data, error } = useQuery(['posts', 'news', offset, limit], fetchData);

    useEffect(() => {
        if (data && data.data && data.data.length > 0) setNews(data.data);
    }, [data]);

    return (
        <YMTableDesktop cols={tableConstants()} paginated={true} count={(data) ? Number(data.count) : 0} page={page - 1} limit={limit} handlePageChange={(page) => setPage(Number(page))} handleLimitChange={(limit) => setLimit(limit)}>
            {
                (news && news.length > 0) 
                ? news.map((newsItem, index) => (
                    <TableRow key={index}>
                        <TableCell>
                            <Box sx={{
                                alignItems: 'center',
                                display: 'flex'
                            }}>
                                <Box sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                }}>
                                    <Avatar src={newsItem.meme.renderedImage.urlOriginal} sx={{ width: 56, height: 56}} variant="square" />
                                </Box>
                                <Typography ml={1} variant="caption">
                                    {newsItem.title}
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell>
                            {newsItem.nComments}
                        </TableCell>
                        <TableCell>
                            {newsItem.nLikes + newsItem.nSuperlikes + newsItem.nHots + newsItem.nIntriguings}
                        </TableCell>
                        <TableCell>
                            <UserInfoCellComponent imageURL={newsItem.user.avatar?.urlOriginal} username={newsItem.user?.username.toLowerCase()} displayName={newsItem.user?.displayName} />
                        </TableCell>
                        <TableCell>
                        { moment(newsItem.createdAt).format('DD/MM/YYYY') } <br/>
                        { moment(newsItem.createdAt).format('HH:MM:SS')}
                        </TableCell>
                        <TableCell>
                            <Box display="flex" width="100px" justifyContent="space-between">
                                <Tooltip title="View news on YouMeme">
                                    <IconButton variant="contained" color="primary" size="large" href={`${process.env.REACT_APP_YOUMEME_FRONTEND_URL}/news/${newsItem.id}`} target="_blank">
                                        <Visibility />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Edit Event ( Coming Soon )">
                                    <span>
                                        <IconButton variant="outlined" color="warning" size="large" onClick={() => navigate(`/dashboard/news/edit/${newsItem.id}`, { state: newsItem })} disabled>
                                            <Edit />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Delete News">
                                    <IconButton variant="contained" color="error" size="large" onClick={() => dispatch({ type: 'DELETE_NEWS', value: newsItem })}>
                                        <DeleteForever />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </TableCell>
                    </TableRow>
                ))
                : <TableRow>
                    <TableCell colSpan={tableConstants().length} >
                        {
                            (isLoading)
                                ? <Loading />
                                : <NoDataFound error={error} />
                        }
                    </TableCell>
                </TableRow>
            }
        </YMTableDesktop>
    )
}

export default NewsTable;
