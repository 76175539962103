import { useEffect, useState } from 'react'
import {
    Box,
    Typography,
    TextField,
    InputAdornment,
    Divider
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import ValueInput from './ValueInput';
import DescField from './DescField';
import FileUpload from './FileUpload';
import { useGlobalMutation } from 'src/utils/useGlobalMutations';
import { addEvent, editEvent, getUser, uploadEventImage } from 'src/api/query'
import { useErrorAction, useSuccessAction } from 'src/utils/mutationResponseAction';
import useUserSearch from 'src/utils/useUserSearch';
import { Check, Facebook, Instagram, Search, Telegram, Twitter } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAlertDispatch } from 'src/contexts/GlobalAlertContext';
import { useQueryClient } from 'react-query';
import diff from 'src/utils/getObjectDiff';


const MemeCompetitionForm = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const isCreateMode = !location.state;

    const addEventAction = useGlobalMutation(addEvent);
    const uploadEventImageAction = useGlobalMutation(uploadEventImage);
    const editEventAction = useGlobalMutation(editEvent);

    // Dispatch actions for each alert
    const { successAlert, errorAlert } = useAlertDispatch();

    //actions the mutations above will perform for each state.
    const errorAction = useErrorAction();
    const successAction = useSuccessAction();

    // Query client
    const queryClient = useQueryClient();

    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "image/webp"
    ];

    // Button state
    const [submitting, toggleSubmit] = useState(false);

    //Internal State
    const [eventData, setEventData] = useState({
        name: '',
        description: '',
        urlWebsite: '',
    })
    const [eventDate, setEventDate] = useState({
        startingDate: {
            formattedDate: null,
            returnedDate: null
        },
        endingDate: {
            formattedDate: null,
            returnedDate: null
        }
    })
    const [eventImage, setEventImage] = useState({
        previewImage: null,
        raw: null
    });
    const [eventImageFormat, setEventImageFormat] = useState(null);
    const [errors, setErrors] = useState({
        name: null,
        urlWebsite: null,
        imageSize: null,
        imageFormat: null,
        description: null,
        maxWinners: null,
        rules: null,
        reward: null,
        cashTotal: null,
        cashUnit: null,
    });
    const [email, setEmail] = useState();
    const [fkUserId, setfkUserId] = useState(null);
    const [searchQuery, setSearchQuery] = useState(null);
    const [isUser, updateIsUser] = useState(false);
    const [contestDetail, setContestDetail] = useState({
        maxWinners: 0,
        rules: '',
        reward: '',
        cashTotal: 0,
        cashUnit: '',
    });
    const [contestSocial, setContestSocial] = useState({
        twitter: '',
        facebook: '',
        instagram: '',
        discord: '',
        telegram: '',
    });

    useEffect(() => {
        if (!isCreateMode) {
            setEventImage(prev => ({
                ...prev, previewImage: location.state.image.urlOriginal
            }));
            // Fetch and set user
            getUser(location.state.fkUserId)
            .then((response) => {
                setEmail(response.data?.data[0]?.email);
                setfkUserId(location.state.fkUserId);
                updateIsUser(true);
            })
            .catch((error) => {
                errorAlert(`Error fetching event owner details.`);
            })
            // Set event data
            setEventData({
                name: location.state.name,
                description: (location.state.description) ? location.state.description : null,
                urlWebsite: (location.state.urlWebsite) ? location.state.urlWebsite : null,
            })
            // Set dates
            setEventDate({
                startingDate: {
                    formattedDate: new Date(location.state.startingDate).toISOString(),
                    returnedDate: new Date(location.state.startingDate),
                },
                endingDate: {
                    formattedDate: new Date(location.state.endingDate).toISOString(),
                    returnedDate: new Date(location.state.endingDate),
                }
            })
            // Set contest details
            setContestDetail({
                ...location.state.eventMetadata.contestDetail,
            })
            // Set contest socials
            setContestSocial({
                ...location.state.eventMetadata.contestDetail.social,
            })
        }
    }, []);

    const validateImageFormat = (type) => {
        const isValid = SUPPORTED_FORMATS.includes(type);
         setErrors(prev => isValid ? ({...prev, imageFormat: null}) : ({...prev, imageFormat: "Image format is not supported"}) );
         return isValid;
    }

    const validateImageSize = (size) => {
        const approvedImageSizeInBytes = 3000000;
        let isValid = false;
        
        if(size < approvedImageSizeInBytes){
          setErrors(prev => ({...prev, imageSize: null}));
          isValid = true;
        }
        else{
          setErrors(prev => ({...prev, imageSize: "Image is too large!"}));
        }
        return isValid;
    }
    
    const validateImage = (file) => {
        const isSizeValid = validateImageSize(file.size);
        const isFormatValid = validateImageFormat(file.type);

        return isSizeValid && isFormatValid;
    }

    const validate = (name, value) => {
        switch(name){
            case 'name':
                const namePattern = /^[^^*|":<>[\]{}`\\();@&$]+$/g;
                const isValid = namePattern.test(value);
                setErrors(prev => isValid ? ({...prev, name: null}) : ({...prev, name: "Name should not be empty or contain special characters"}));
                const isValidLength = value.length < 70;
                setErrors(prev => isValidLength ? ({...prev, name: null}) : ({...prev, name: "Event name should be less than 70 characters."}));
                break;
            
            case 'urlWebsite':
                const urlPattern = /[(http(s)?):\\/(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,20}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/ig;
                const isURLValid = urlPattern.test(value);
                setErrors(prev => isURLValid ? ({...prev, urlWebsite: null}) : ({...prev, urlWebsite: "Please enter a valid URL"}));
                break;

            case 'email':
                const emailPattern = /^[a-z0-9._%+-]+@[a-zA-Z0-9.-]+?\.[a-zA-Z]{2,20}$/;
                const isEmailValid = emailPattern.test(value);
                setErrors(prev => isEmailValid ? ({...prev, email: null}) : ({...prev, email: "Please enter a valid email address"}));
                if (isEmailValid) {
                    setSearchQuery(value);
                } else {
                    if (isUser) {
                        updateIsUser(false);
                        setfkUserId(null);
                    }
                }
                break;

            case 'description':
                let isCorrect = value.length < 5000;
                setErrors(prev => isCorrect ? ({...prev, description: null}) : ({...prev, description: "Please enter less than 5000 characters."}));
                break;

            case 'maxWinners':
                let checkWinner = value > 0 && value < 1000;
                setErrors(prev => checkWinner ? ({...prev, winners: null}) : ({...prev, winners: "Value should be between 1 and 1000."}));
                break;

            case 'rules':
                let checkRules = value.length < 5000;
                setErrors(prev => checkRules ? ({...prev, rules: null}) : ({...prev, rules: "Rules should be less than 5000 characters."}));
                break;
            
            case 'reward':
                let checkRewards = value.length < 5000;
                setErrors(prev => checkRewards ? ({...prev, reward: null}) : ({...prev, reward: "Rewards should be less than 5000 characters."}));
                break;
            case 'cashTotal':
                let checkCashTotal = value >= 0;
                setErrors(prev => checkCashTotal ? ({...prev, cashTotal: null}) : ({...prev, cashTotal: "Cash total should be greater than 0"}));
                break;
            case 'cashUnit':
                let checkCashUnit = contestDetail.cashTotal > 0 && !value;
                setErrors(prev => checkCashUnit ? ({...prev, cashUnit: "Please specify a unit"}) : ({...prev, cashUnit: null}))
            default:
                break;
        }
    }

    const handleChange = (e) => {
        setEventData({ ...eventData, [e.target.name]: e.target.value });
        validate(e.target.name, e.target.value)
    };

    const handleDateChange = (date, name) => {
        setEventDate({
            ...eventDate, 
            [name]: {
                formattedDate: date.toISOString(),
                returnedDate: date
            }
        });
    }

    const handleRuleChange = (e) => {
        setContestDetail({
            ...contestDetail, 
            [e.target.name]: e.target.value
        });
        validate(e.target.name, e.target.value);
    }

    const handleSocialUpdate = (e) => {
        setContestSocial({
            ...contestSocial,
            [e.target.name]: e.target.value
        });
    }

    const handleFileUpload = (e) => {
        let file = e.target.files[0];
        let format = file.type.replace('image/', '');
        let fileValid = validateImage(file);
        setEventImage({
            previewImage: URL.createObjectURL(file),
            raw: file
        });
        setEventImageFormat(format)

        // send image to server if edit mode
        if (!isCreateMode && fileValid) {
            toggleSubmit(true);
            uploadEventImageAction.mutate({
                id: location.state.id,
                image: {
                    type: format,
                    size: file.size,
                },
                data: file,
            }, {
                onSuccess: () => {
                    successAlert(`Competition image updated!`);
                    toggleSubmit(false);
                },
                onError: (error) => {
                    errorAlert(error.message);
                    toggleSubmit(false);
                }
            })
        }
    }

    const preSumbit = (values) => {
        let initialValues = {
            name: location.state.name,
            description: location.state.description,
            urlWebsite: location.state.urlWebsite,
            startingDate: new Date(location.state.startingDate).toISOString(),
            endingDate: new Date(location.state.endingDate).toISOString(),
            userId: location.state.fkUserId,
            eventMetadata: location.state.eventMetadata,
        }

        let finalValues = values;
        finalValues = diff(initialValues, values);
        return finalValues;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        const eventMetadata = {
            contestDetail: {
                ...contestDetail,
                social: {
                    ...contestSocial
                }
            }
        }

        formData.append('startingDate', eventDate.startingDate.formattedDate);
        formData.append('endingDate', eventDate.endingDate.formattedDate);
        formData.append('urlWebsite', eventData.urlWebsite);
        formData.append('userId', fkUserId);
        formData.append('name', eventData.name);
        formData.append('description', eventData.description); 
        formData.append('type', 'competition');
        formData.append('eventMetadata', JSON.stringify(eventMetadata));

        if (isCreateMode) {
            toggleSubmit(true);
            formData.append('eventImageFormat', eventImageFormat);
            formData.append('eventImage', eventImage.raw);
            let fileValid = validateImage(eventImage.raw);

            if(fileValid && errors.name == null && errors.urlWebsite == null && fkUserId){
                toggleSubmit(true);
                addEventAction.mutate(formData, {
                    onSuccess: (data, variables, contexts) => {
                        successAction('events', "/dashboard/events/memecompetition", "Meme competition added");
                        toggleSubmit(false);
                    },
                    onError: (error, variables, contexts) => {
                        errorAction(error.message);
                        toggleSubmit(false);
                    },
                })
            }
        } else {
            toggleSubmit(true);
            const editedData = preSumbit({
                name: eventData.name,
                description: eventData.description,
                startingDate: eventDate.startingDate.formattedDate,
                endingDate: eventDate.endingDate.formattedDate,
                userId: fkUserId,
                eventMetadata: eventMetadata,
                urlWebsite: eventData.urlWebsite,
            });
            editEventAction.mutate({
                id: location.state.id,
                data: editedData,
            }, {
                onSuccess: (data) => {
                    successAlert(`Competition updated`);
                    queryClient.setQueryData(['events', { id: location.state.id }], data);
                    toggleSubmit(false);
                    navigate("/dashboard/events/memecompetition");
                },
                onError: (error) => {
                    toggleSubmit(false);
                    errorAlert(error.message);
                }
            })
        }

    }

    // Effects
    useEffect(() => {
        if (email) {
            validate('email', email);
        }
    }, [email])

    // Search 
    const searchResult = useUserSearch(searchQuery);

    useEffect(() => {
        if (searchResult.data && searchResult.data.data && searchResult.data.data.length > 0) {
            setErrors(prev => ({...prev, email: null}));
            updateIsUser(true);
            setfkUserId(searchResult.data.data[0].id);
            return;
        }

        if (searchResult.error) {
            setErrors(prev => ({...prev, email: 'Unable to find user, please verify email.'}));
            updateIsUser(false);
            setfkUserId(null);
            return;
        }
    }, [searchResult]);

    const handleKeyDown = (e) => {
        if (e.code === 'Enter') validate('email', email);
    }

    return (
        <Box sx={{
            maxWidth: 'md'
        }}> 
            <Box>
                <ValueInput
                    label="Sponsoring User"
                    type="email"
                    placeholder='Seach user by email'
                    name='email'
                    value={email || ''}
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                    error={errors.email && Boolean(errors.email)}
                    helperText={errors.email && errors.email}
                    onKeyDown={(e) => handleKeyDown(e)}
                    InputProps={{
                        endAdornment:
                            (!isUser)
                                ? <InputAdornment position='end'>
                                    <Search onClick={() => validate('email', email)} sx={{ cursor: 'pointer' }} />
                                </InputAdornment>
                                : <InputAdornment position='end'><Check /></InputAdornment>
                    }}
                />
            </Box>
            <Box
                sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 3
                }}
            >
                <FileUpload
                required
                name="eventImage"
                value={eventImage}
                accept={SUPPORTED_FORMATS.join(', ')}
                onImageChange={handleFileUpload}
                />
                <Box
                sx={{
                    ml: 5
                }}
                >
                    <Typography
                        color="#313131"
                        fontSize="16px"
                    >
                        Upload Thumbnail
                    </Typography>
                    <Typography
                        color="#828282"
                        fontSize="14px"
                    >
                        80px &times; 80px at Max 3MB
                    </Typography>

                    <Typography color="#ff0000">
                        { errors.imageSize && errors.imageSize }
                    </Typography>
                    <Typography color="#ff0000">
                        {errors.imageFormat && errors.imageFormat}
                    </Typography>
                
                </Box>
            </Box>
            <Box sx={{
                maxWidth: 200,
                my: 3,
                '& img': {
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%'
                    }
            }}>
                {eventImage.previewImage ? <img src={eventImage.previewImage} alt="preview"/> : null}
            </Box>
            <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Box>
                    <ValueInput
                        variant="outlined"
                        required
                        placeholder="Enter a name for event"
                        name="name"
                        value={eventData.name}
                        onChange={handleChange}
                        error={errors.name && Boolean(errors.name)}
                        helperText={errors.name && errors.name}
                    />
                </Box>
                <Box>
                   <DescField
                        style={{opacity:0.4}}
                        multiline
                        rows={3}
                        required
                        placeholder="Disabled"
                        disabled
                        name="description"
                        value={eventData.description || ''}
                        onChange={handleChange}
                        error={errors.description && Boolean(errors.description)}
                        helperText={errors.description && errors.description}
                    />
                </Box>
                <Box>
                <DescField
                        placeholder="Short description + Rules"
                        multiline
                        rows={3}
                        name="rules"
                        value={contestDetail.rules}
                        onChange={handleRuleChange}
                        error={errors.rules && Boolean(errors.rules)}
                        helperText={errors.rules && errors.rules}
                    />
                </Box>
                <Box>
                    <ValueInput
                        variant="outlined"
                        placeholder="Enter a url for event (https://example.com)"
                        type='url'
                        name="urlWebsite"
                        value={eventData.urlWebsite}
                        onChange={handleChange}
                        error={errors.urlWebsite && Boolean(errors.urlWebsite)}
                        helperText={errors.urlWebsite && errors.urlWebsite}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 4
                }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            label="Start Date"
                            name="startingDate"
                            value={eventDate.startingDate.returnedDate}
                            allowSameDateSelection
                            inputFormat='dd/MM/yyyy hh:mm a'
                            onChange={date => handleDateChange(date,"startingDate")}
                            minDateTime={new Date()}
                            renderInput={(params) => <TextField {...params} 
                                                        error={errors.startingDate && Boolean(errors.startingDate)}
                                                        helperText={errors.startingDate && errors.startingDate}
                                                        sx={{ flexGrow: '1', marginRight: '.5em', backgroundColor: '#ffffff' }}
                                                    />
                                        }
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            label="End Date"
                            name="endingDate"
                            value={eventDate.endingDate.returnedDate}
                            allowSameDateSelection
                            inputFormat='dd/MM/yyyy hh:mm a'
                            onChange={date => handleDateChange(date,"endingDate")}
                            minDateTime={new Date()}
                            renderInput={(params) => <TextField {...params} 
                                                        error={errors.endingDate && Boolean(errors.endingDate)}
                                                        helperText={errors.endingDate && errors.endingDate}
                                                          sx={{ flexGrow: '1', marginLeft: '.5em', backgroundColor: '#ffffff'  }}
                                                    />
                            }
                        />
                    </LocalizationProvider>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <ValueInput
                        variant='outlined'
                        type='url'
                        placeholder= 'Twitter link for event ( optional )'
                        name="twitter"
                        value={contestSocial.twitter}
                        onChange={handleSocialUpdate}
                        error={errors.social?.twitter && Boolean(errors.social?.twitter)}
                        helperText={errors.social?.twitter && errors.social?.twitter}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <Twitter />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ flexGrow: '1', marginRight: '.5em', backgroundColor: '#ffffff' }}
                    />
                    <ValueInput
                        variant='outlined'
                        type='url'
                        placeholder= 'Telegram link for event (optional )'
                        name="telegram"
                        value={contestSocial.telegram}
                        onChange={handleSocialUpdate}
                        error={errors.social?.telegram && Boolean(errors.social?.telegram)}
                        helperText={errors.social?.telegram && errors.social?.telegram}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <Telegram />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ flexGrow: '1', marginRight: '.5em', backgroundColor: '#ffffff' }}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <ValueInput
                        variant='outlined'
                        type='url'
                        placeholder= 'Facebook link for event ( optional )'
                        name="facebook"
                        value={contestSocial.facebook}
                        onChange={handleSocialUpdate}
                        error={errors.social?.facebook && Boolean(errors.social?.facebook)}
                        helperText={errors.social?.facebook && errors.social?.facebook}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <Facebook />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ flexGrow: '1', marginRight: '.5em', backgroundColor: '#ffffff' }}
                    />
                    <ValueInput
                        variant='outlined'
                        type='url'
                        placeholder= 'Instagram link for event (optional )'
                        name="instagram"
                        value={contestSocial.instagram}
                        onChange={handleSocialUpdate}
                        error={errors.social?.instagram && Boolean(errors.social?.instagram)}
                        helperText={errors.social?.instagram && errors.social?.instagram}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <Instagram />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ flexGrow: '1', marginRight: '.5em', backgroundColor: '#ffffff' }}
                    />
                </Box>
                <Divider sx={{
                    marginBottom: 2
                }}>
                    REWARDS
                </Divider>
                <Box>
                    <ValueInput
                        variant="outlined"
                        required
                        placeholder="Number of winners ( between 1 - 1000 )"
                        label="Total number of winners"
                        name="maxWinners"
                        type='number'
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                        value={contestDetail.maxWinners}
                        onChange={handleRuleChange}
                        error={errors.maxWinners && Boolean(errors.maxWinners)}
                        helperText={errors.maxWinners && errors.maxWinners}
                    />
                </Box>
                <Box>
                <DescField
                        multiline
                        required
                        rows={3}
                        placeholder="Detail of Reward"
                        name="reward"
                        value={contestDetail.reward}
                        onChange={handleRuleChange}
                        error={errors.reward && Boolean(errors.reward)}
                        helperText={errors.reward && errors.reward}
                    />
                </Box>
                <Divider sx={{
                    marginBottom: 2
                }}>
                    CASH REWARDS
                </Divider>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <ValueInput
                        variant="outlined"
                        placeholder="Total Reward"
                        label="Sum total if cash reward"
                        name="cashTotal"
                        type='number'
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                        value={contestDetail.cashTotal}
                        onChange={handleRuleChange}
                        error={errors.cashTotal && Boolean(errors.cashTotal)}
                        helperText={errors.cashTotal && errors.cashTotal}
                        sx={{ flexGrow: '1', marginRight: '.5em', backgroundColor: '#ffffff' }}
                    />
                    <ValueInput
                        variant="outlined"
                        placeholder="Currency / Token"
                        label="Cash Reward Currency or Token"
                        name="cashUnit"
                        value={contestDetail.cashUnit}
                        onChange={handleRuleChange}
                        error={errors.cashUnit && Boolean(errors.cashUnit)}
                        helperText={errors.cashUnit && errors.cashUnit}
                        sx={{ flexGrow: '1', marginRight: '.5em', backgroundColor: '#ffffff' }}
                    />
                </Box>
                <LoadingButton
                        size="large"
                        type="submit"
                        variant="contained"
                        disabled={submitting}
                        loading={submitting}
                    >
                        {
                            (isCreateMode) ? "Add Meme Competition" : "Update Meme Competition"
                        }
                </LoadingButton>
            </form>
        </Box>
    )

}

export default MemeCompetitionForm;
