import { MilitaryTech } from "@mui/icons-material";
import { Avatar, Badge, Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { Draggable } from "react-drag-reorder";
import { useModalDispatch } from "src/contexts/GlobalModalContext";

const PostRanking = ({ state }) => {
  const dispatch = useModalDispatch();
  const [currentWinners, setCurrentWinners] = useState(state.data);

  const handlePositionChange = async (currentPos, newPos) => {
    let itemToBeMoved = currentWinners[currentPos];
    let currentArray = currentWinners;
    currentArray.splice(currentPos, 1);
    currentArray.splice(newPos, 0, itemToBeMoved);
    let newArray = await currentArray.map((each, index) =>
      Object.assign({}, each, { rank: index + 1 })
    );
    dispatch({ type: "UPDATE_MODAL_PROPS", value: newArray });
  };

  return (
    <Grid container direction='row' justifyContent='center' alignItems='center'>
      <Grid
        item
        container
        direction='column'
        justifyContent='space-around'
        alignItems='center'
        xs={2}
        sx={{ padding: "2px", margin: "2px" }}
      >
        {currentWinners.map((each, idx) => {
          return (
            <Grid
              item
              sx={{ height: 60, margin: "5px" }}
              alignItems='center'
              justifyContent='center'
              display='flex'
            >
              <Badge
                badgeContent={idx + 1}
                color='primary'
                sx={{ alignContent: "center" }}
              >
                <MilitaryTech />
              </Badge>
            </Grid>
          );
        })}
      </Grid>
      <Grid item xs={8}>
        <Draggable
          onPosChange={(currentPos, newPos) =>
            handlePositionChange(currentPos, newPos)
          }
        >
          {currentWinners.map((each, idx) => {
            return (
              <Paper
                elevation={1}
                sx={{ marginTop: "2px", padding: "2px" }}
                key={idx}
              >
                <Grid
                  container
                  direction='row'
                  alignContent='center'
                  alignItems='center'
                  justifyContent='space-evenly'
                  sx={{ margin: "5px" }}
                >
                  <Grid item>
                    <Avatar
                      src={each.post?.meme?.renderedImage?.urlOriginal}
                      sx={{ width: 56, height: 56 }}
                      variant='rounded'
                      alt='Preview'
                    />
                  </Grid>
                  <Grid item>
                    <Avatar
                      src={each.post?.user?.avatar?.urlOriginal}
                      sx={{ width: 56, height: 56 }}
                      variant='rounded'
                      alt='Preview'
                    />
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
        </Draggable>
      </Grid>
    </Grid>
  );
};

export default PostRanking;
