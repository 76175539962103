import BanUserForm from "src/components/forms/BanUserForm";

export const MODAL_DEFAULTS = {
  DELETE_USER_MODAL: {
    title: "Delete User",
    text: "Are you sure you want to delete the user ?",
    color: "error",
    type: "user",
    query: "users",
    successMessage: "User Deleted",
  },
  VERIFY_USER_MODAL: {
    title: "Verify User",
    text: "Are you sure you want to verify the user ?",
    color: "success",
    type: "user",
    query: "users",
    successMessage: "User Verified",
  },
  UNVERIFY_USER_MODAL: {
    title: "Unverify User",
    text: "Are you sure you want to unverify the user ?",
    color: "error",
    type: "user",
    query: "users",
    successMessage: "User Unverified",
  },
  DELETE_POST_MODAL: {
    title: "Delete Post",
    text: "Are you sure you want to delete the post ?",
    color: "error",
    type: "post",
    query: "posts",
    successMessage: "Post Deleted",
  },
  REWARD_POST_MODAL: {
    title: "Reward Post",
    text: "Are you sure you want to reward the post ?",
    color: "info",
    type: "post",
    query: "event",
    successMessage: "Post Rewarded",
  },
  DELETE_AGENT_MODAL: {
    title: "Delete Agent",
    text: "Are you sure you want to delete the agent ?",
    color: "error",
    type: "user",
    query: "agents",
    successMessage: "Agent Deleted",
  },
  DELETE_EVENT_MODAL: {
    title: "Delete Event",
    text: "Are you sure you want to delete the event ?",
    color: "error",
    type: "event",
    query: "events",
    successMessage: "Event Deleted",
  },
  DELETE_TEMPLATE_MODAL: {
    title: "Delete Template",
    text: "Are you sure you want to delete the template ?",
    color: "error",
    type: "template",
    query: "templates",
    successMessage: "Template Deleted",
  },
  DELETE_STICKER_MODAL: {
    title: "Delete Sticker",
    text: "Are you sure you want to delete the sticker ?",
    color: "error",
    type: "sticker",
    query: "stickers",
    successMessage: "Sticker Deleted",
  },
  BAN_USER_MODAL: {
    title: "Ban User",
    text: "Are you sure you want to ban the following user ?",
    color: "warning",
    type: "user",
    query: "banuser",
    successMessage: "User Banned",
    hasFormNode: true, // TODO: Temporary - Replace when other modal nodes are wrapped within context
    form: <BanUserForm />,
  },
  UPDATE_BAN_USER_MODAL: {
    title: "Update User Ban",
    text: "Are you sure you want to update the ban ?",
    color: "warning",
    type: "user",
    query: "banuser",
    successMessage: "User Ban Updated !",
    hasFormNode: true, // TODO: Temporary - Replace when other modal nodes are wrapped within context
    form: <BanUserForm />,
  },
  UNBAN_USER_MODAL: {
    title: "Delete User Ban",
    text: "Are you sure you want to delete ban for the following user ?",
    color: "warning",
    type: "user",
    query: "banuser",
    successMessage: "User Ban Deleted",
  },
  DELETE_REWARDED_POST_MODAL: {
    title: "Delete Rewarded Post",
    text: "Are you sure you want to remove the post as winner ?",
    color: "error",
    type: "post",
    query: "event",
    successMessage: "Removed post as winner",
  },
  CLOSE_EVENT_MODAL: {
    title: "Reward and Close Event",
    text: "You have selected the following posts as winners, confirm to continue and close the event. Drag and drop to change ranking.",
    color: "info",
    type: "submission",
    query: "event",
    successMessage: "Successfully submitted the winners list.",
  },
  DELETE_CATEGORY_MODAL: {
    title: "Delete Category",
    text: "Are you sure you want to delete the following category ?",
    color: "error",
    type: "category",
    query: "category",
    successMessage: "Successfully deleted the category.",
  },
  DELETE_NEWS_MODAL: {
    title: "Delete News",
    text: "Are you sure you want to delete the news ?",
    color: "error",
    type: "post",
    query: ["posts", "news"],
    successMessage: "News Deleted",
  },
};
