import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Container, Typography } from '@mui/material';

import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/forms/login';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
}));
  
const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    margin: theme.spacing(2, 0, 2, 2),
    backgroundColor: theme.palette.background.primary
}));
  
const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
}));

const Login = () => {

    const navigate = useNavigate();
    const isUser = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        if (isUser) navigate('/dashboard/home');
    }, [])

    return (
        <RootStyle title="Login - YM Admin">
            <MHidden width="mdDown">
                <SectionStyle>
                    <Box sx={{ px: 5 }}>
                        <img src="/static/images/main_logo.png" width="max-content" alt="login" />
                    </Box>
                </SectionStyle>
            </MHidden>

            <Container maxWidth="sm">
                <ContentStyle>
                    <Stack sx={{ mb: 5}}>
                        <Typography variant="h4" gutterBottom>
                            Welcome back, admin!
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>Please enter account details to login !</Typography>
                    </Stack>

                    <LoginForm />
                </ContentStyle>
            </Container>
        </RootStyle>
    );
}

export default Login;
