import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { useAlertDispatch } from 'src/contexts/GlobalAlertContext'

export const useSuccessAction = () => {

  const { successAlert } = useAlertDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  
  function handleSuccess(query, path, message) {
    queryClient.invalidateQueries(query);
    successAlert(message);
    navigate(path);
  }

  return handleSuccess;
}

export const useErrorAction = () => {

  const { errorAlert } = useAlertDispatch();

  function handleError(message){
    errorAlert(message);
  }

  return handleError;
}