import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { alpha, useTheme } from '@mui/material/styles';
import { Button, Box, MenuItem, Avatar, IconButton } from '@mui/material';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';

import MenuPopover from '../../components/layout/MenuPopover';
import AccountInfo from '../../components/layout/AccountInfo';
import { MHidden } from '../../components/@material-extend';
import { useAuthState, useAuthDispatch } from 'src/contexts/UserAuthContext';

const MENU_OPTIONS = [
  {
    label: 'YouMeme Web',
    icon: personFill,
    linkTo: process.env.REACT_APP_YOUMEME_FRONTEND_URL,
    newPage: true
  },
  {
    label: 'Change Avatar',
    icon: settings2Fill,
    linkTo: '#',
    newPage: false
  }
];

const AccountPopover = () => {
  const theme = useTheme();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { user } = useAuthState();
  const { logOut } = useAuthDispatch();
  const userAvatar = user.avatar?.urlOriginal;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleButtonClick = () => {
    logOut();
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar alt="photoURL" src={userAvatar}/>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <MHidden width="lgUp">
          <AccountInfo />
        </MHidden>
        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            //to={option.linkTo}
            //component={RouterLink} 
            component="a"
            href={option.linkTo}
            target={option.newPage && "_blank"}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5, color: theme.palette.primary.main }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="primary" variant="contained" onClick={handleButtonClick}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

export default AccountPopover;
