import { Box, Container, Typography, Divider, Button } from "@mui/material";
import { useQuery, useQueryClient } from "react-query";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Loading from "src/components/Loading";
import Page from "src/components/Page";
import TemplateEditForm from "src/components/templates/TemplateEditForm";
import { useAuthState } from "src/contexts/UserAuthContext";
import { useGlobalMutation } from "src/utils/useGlobalMutations";
import { getTemplate, editTemplate } from "src/api/query/template";
import {
  useErrorAction,
  useSuccessAction,
} from "src/utils/mutationResponseAction";
import { useAlertDispatch } from "src/contexts/GlobalAlertContext";

const TemplateEdit = () => {
  const { templateId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // const [initialValues, setInitialValues] = useState(null);

  // Calls the action that will upload the image in edit mode
  // const uploadEventImageAction = useGlobalMutation(uploadEventImage);

  const fetchData = () => {
    return getTemplate(templateId);
  };

  const { isLoading, data, error } = useQuery(
    [`templates?id=${templateId}`],
    fetchData
  );

  // Calls the action that will perform the edit event action and update the event list
  const editTemplateAction = useGlobalMutation(editTemplate);

  // Dispatch actions for each alert
  const { successAlert, errorAlert } = useAlertDispatch();

  const queryClient = useQueryClient();

  // prepare my formik data
  const {
    title,
    description,
    tags,
    isNsfw,
    isPublic,
    sortPosition,
    templateImage,
    hasFace,
  } = data?.data?.[0] ?? {};

  const initialValues = {
    title,
    description,
    tags,
    isNsfw,
    isPublic,
    sortPosition,
    previewImage: templateImage?.jpegUrl,
    hasFace,
  };

  // just ensuring that image is returned
  if (!templateImage?.id) {
    initialValues.templateImage = "";
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(30, "Too long!").required("Required"),
    description: Yup.string().required("Required"),
    tags: Yup.array().max(3, "Maximum of 3 tags allowed"),
    sortPosition: Yup.number().required("Required"),
    isNsfw: Yup.boolean().required("Required"),
    isPublic: Yup.boolean().required("Required"),
    hasFace: Yup.boolean().required("Required"),
    previewImage: Yup.string().required("Required"),
  });

  const onSubmit = (props, { setSubmitting }) => {
    delete props.previewImage;
    const newData = {
      ...props,
      tags: props?.tags?.filter((item) => item),
    };
    editTemplateAction.mutate(
      {
        id: templateId,
        data: newData,
      },
      {
        onSuccess: (data) => {
          console.log({ data });
          successAlert(`Template Updated`);
          queryClient.invalidateQueries(`templates?id=${templateId}`);
          navigate("/dashboard/templates");
        },
        onError: (error) => {
          errorAlert(error.message);
          setSubmitting(false);
        },
      }
    );
  };

  return (
    <Page title='Simple Events | YM Admin'>
      <Container maxWidth='xl'>
        <Box>
          <Typography color='textPrimary' variant='h2'>
            Edit Template
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0.5,
          }}
        >
          <Typography color='textSecondary' variant='body2'>
            {pathname}
          </Typography>

          <Link to='/dashboard/templates'>
            <Button
              variant='contained'
              color='primary'
              sx={{
                marginBottom: 1,
              }}
            >
              GO BACK
            </Button>
          </Link>
        </Box>
        <Divider />
        <Box sx={{ pt: 3 }}>
          {isLoading && <Loading />}
          {initialValues?.title && (
            <TemplateEditForm
              templateId={templateId}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            />
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default TemplateEdit;
