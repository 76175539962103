import ProtectedRouter from './routes/index';
import { QueryClient, QueryClientProvider } from 'react-query'
import { UserAuthProvider } from './contexts/UserAuthContext';
import { GlobalModalProvider } from './contexts/GlobalModalContext';
import { GlobalAlertProvider } from './contexts/GlobalAlertContext';

import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import Alerts from './components/common/Alerts';
import Modal from './components/common/Modal';

import 'src/mixins/chartjs';
import './global.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './youmeme-data/ymca'

const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <GlobalAlertProvider>
                <UserAuthProvider>
                    <ThemeConfig>
                        <GlobalModalProvider>
                            <GlobalStyles />
                            <ProtectedRouter />
                            <Alerts />
                            <Modal />
                        </GlobalModalProvider>
                    </ThemeConfig>
                </UserAuthProvider>
            </GlobalAlertProvider>
        </QueryClientProvider>
    );
}

export default App;
