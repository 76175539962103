import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getData } from "src/utils/getData";
import { PropTypes } from "prop-types";
import moment from "moment";

import { TableCell, TableRow, IconButton, Box, Avatar, Typography, Tooltip, Chip } from "@mui/material";
import { DeleteForever, Edit } from "@mui/icons-material";

import YMTableDesktop from "../common/YMTableDesktop";
import NoDataFound from "../NoDataFound";
import Loading from '../Loading';
import EventDetail from './EventDetail'

import { useModalDispatch } from "src/contexts/GlobalModalContext";
import { useNavigate } from "react-router-dom";
import { Roles } from "src/_helpers/role";
import { useAuthState } from "src/contexts/UserAuthContext";

const tableConstants = () => {
  return [
    {
      title: 'Event Details',
      key: 'eventDetails',
    },
    {
      title: 'Status',
      key: 'status',
    },
    {
      title: 'Start Date',
      key: 'startDate',
    },
    {
      title: 'End Date',
      key: 'endDate'
    },
    {
        title: 'Action',
        key: 'action',
    }
  ];
};

const EventsTable = () => {

    const dispatch = useModalDispatch();
    const navigate = useNavigate();
    const { user } = useAuthState();

    const now = Date.now();

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(100);
    const [offset, setOffset] = useState(0);
    const [events, setEvents] = useState([]);

    useEffect(() => {
      if (page === 1 && offset === 0) return;
      setOffset((page - 1) * limit);
    }, [page]);

    const fetchData = () => {
      if (user && user.role === Roles.EVENT_MANAGER) return getData(`/event?ownerId=${user.id}&offset=${offset}&limit=${limit}&expired=true&order=asc&type=default&sort=endingDate`)
      else return getData(`/event?offset=${offset}&limit=${limit}&expired=true&order=asc&type=default&sort=endingDate`);
    }

    const { isLoading, data, error } = useQuery(['events', 'default', offset, limit], fetchData);

    useEffect(() => {
      if (data && data.data && data.data.length > 0) {
        let liveEvents = data.data.filter(event => new Date(event.startingDate).getTime() < now && new Date(event.endingDate).getTime() > now);
        let upcomingEvents = data.data.filter(event => new Date(event.startingDate).getTime() > now);
        let closedEvents = data.data.filter(event => new Date(event.endingDate).getTime() < now);
        setEvents([
          ...liveEvents,
          ...upcomingEvents,
          ...closedEvents,
        ])
      }
    }, [isLoading, data, error]);

    return (
        <YMTableDesktop cols={tableConstants()} count={(data) ? Number(data.count): 0} page={page - 1} limit={limit} handlePageChange={(page) => setPage(Number(page))} handleLimitChange={(limit) => setLimit(Number(limit))}>
            {
              (events.length > 0)
              ? events.map((event, index) => (
                <TableRow key={index} hover>
                  <TableCell>
                    <EventDetail image={event.image.urlOriginal} url={event.urlWebsite} name={event.name.toUpperCase()} />
                  </TableCell>
                  <TableCell>
                      <Chip
                        label={
                          (new Date(event.endingDate).getTime() < now)
                            ? "Finished"
                            : (new Date(event.startingDate).getTime() < now)
                              ? "Live"
                              : "Upcoming"
                        }
                        color={
                          (new Date(event.endingDate).getTime() < now)
                            ? "error"
                            : (new Date(event.startingDate).getTime() < now)
                              ? "success"
                              : "warning"
                        }
                      />
                  </TableCell>
                  <TableCell>
                    { moment(new Date(event.startingDate)).format('DD/MM/YYYY') }
                  </TableCell>
                  <TableCell>
                    { moment(new Date(event.endingDate)).format('DD/MM/YYYY') }
                  </TableCell>
                  <TableCell>
                    <Box display="flex" width="100px" justifyContent="space-between">
                      <Tooltip title="Edit Event">
                        <IconButton variant="outlined"  color="warning" size="large" onClick={() => navigate(`/dashboard/events/main/edit/${event.id}`, { state: event })}>
                          <Edit/>
                        </IconButton>
                      </Tooltip>
                      {
                        (user.role === Roles.SUPER_ADMIN || user.role === Roles.ADMIN)
                          ?
                            <Tooltip title="Delete Event">
                              <IconButton variant="contained" color="error" size="large" onClick={() => dispatch({ type: 'DELETE_EVENT', value: event})}><DeleteForever /></IconButton>
                            </Tooltip>
                          :
                            <>
                            </>
                      }
                    </Box>
                  </TableCell>
                </TableRow>
              ))
              : <TableRow>
                  <TableCell colSpan={tableConstants().length} >
                    {
                      (isLoading)
                        ? <Loading />
                        : <NoDataFound error={error} />
                    }
                  </TableCell>
                </TableRow>
            }
        </YMTableDesktop>
    )
}

EventsTable.propTypes = {
    data: PropTypes.array,
}

export default EventsTable;
