import React from "react";
import { useQueryClient } from "react-query";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
  Box,
} from "@mui/material";

import {
  useModalState,
  useModalDispatch,
} from "src/contexts/GlobalModalContext";
import { useAlertDispatch } from "src/contexts/GlobalAlertContext";
import { useGlobalMutation } from "../../utils/useGlobalMutations";
import {
  deleteUser,
  deleteEvent,
  deletePost,
  sponserUser,
  updateUserRole,
  unbanUser,
  deleteWinningPost,
  finalizeEventWinners,
  deleteCategory,
} from "src/api/query";
import { deleteTemplate } from "src/api/query/template";
import ModalInfoCell from "./ModalInfoCell";
import { MODAL_DEFAULTS } from "src/_helpers/modals";
import { deleteSticker } from "src/api/query/sticker";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const Modal = () => {
  const { showModal, modalTypeDefaults, modalProps } = useModalState();
  const dispatch = useModalDispatch();

  const { successAlert, errorAlert } = useAlertDispatch();

  const queryClient = useQueryClient();

  const positiveActions = {
    "Delete User": useGlobalMutation(deleteUser),
    "Delete Event": useGlobalMutation(deleteEvent),
    "Delete Post": useGlobalMutation(deletePost),
    "Verify User": useGlobalMutation(sponserUser),
    "Unverify User": useGlobalMutation(sponserUser),
    "Delete Agent": useGlobalMutation(updateUserRole),
    "Delete User Ban": useGlobalMutation(unbanUser),
    "Delete Rewarded Post": useGlobalMutation(deleteWinningPost),
    "Reward and Close Event": useGlobalMutation(finalizeEventWinners),
    "Delete Category": useGlobalMutation(deleteCategory),
    "Delete News": useGlobalMutation(deletePost),
    [MODAL_DEFAULTS.DELETE_TEMPLATE_MODAL.title]:
      useGlobalMutation(deleteTemplate),
    [MODAL_DEFAULTS.DELETE_STICKER_MODAL.title]:
      useGlobalMutation(deleteSticker),
  };

  const handleAgreeClick = () => {
    positiveActions[modalTypeDefaults.title].mutate(modalProps, {
      onSuccess: () => {
        successAlert(`${modalTypeDefaults.successMessage}!`);
        queryClient.invalidateQueries(modalTypeDefaults.query);
      },
      onError: () => {
        errorAlert("Something went wrong. Please try again.");
      },
    });
    dispatch({ type: "HIDE_MODAL" });
  };

  return (
    <Dialog open={showModal} TransitionComponent={Transition} maxWidth='md'>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {modalTypeDefaults.title}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>{modalTypeDefaults.text}</DialogContentText>

        {/**
         *  #TODO: Wrap the following within the context and
         *  pass down as prop.
         */}
        {modalTypeDefaults.hasFormNode ? (
          <Box>
            <>
              <DialogContentText
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "5px",
                }}
              >
                <ModalInfoCell
                  type={modalTypeDefaults.type}
                  modalprops={modalProps}
                />
              </DialogContentText>

              <br />
              {modalTypeDefaults.form}
            </>
          </Box>
        ) : (
          <Box>
            <DialogContentText
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <ModalInfoCell
                type={modalTypeDefaults.type}
                modalprops={modalProps}
              />
            </DialogContentText>

            <DialogActions>
              <Button
                variant='outlined'
                onClick={() => dispatch({ type: "HIDE_MODAL" })}
                color='primary'
              >
                Disagree
              </Button>
              <Button
                variant='contained'
                color={modalTypeDefaults.color}
                onClick={handleAgreeClick}
              >
                Agree
              </Button>
            </DialogActions>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default Modal;
