import { useEffect, useState } from 'react';
import { useInfiniteQuery, useQueryClient } from "react-query";
import { getData } from "src/utils/getData";
import { PropTypes } from "prop-types";
import moment from "moment";

import { useModalDispatch } from 'src/contexts/GlobalModalContext';

import { TableCell, TableRow, Switch, IconButton, Box, Tooltip } from "@mui/material";
import { Visibility, DeleteForever, Block, ContentCopy } from "@mui/icons-material";

import YMTableDesktop from "../common/YMTableDesktop";
import NoDataFound from "../NoDataFound";
import UserInfoCellComponent from "./UserInfoCellComponent";
import Loading from '../Loading';
import { useAlertDispatch } from 'src/contexts/GlobalAlertContext';


const tableConstants = () => {
  return [
    {
      title: 'User Details',
      key: 'user',
    },
    {
      title: 'Email/Wallet',
      key: 'email',
    },
    {
      title: 'Total Posts',
      key: 'nPosts',
    },
    {
      title: 'Country',
      key: 'country',
    },
    {
      title: 'Join Date',
      key: 'createdAt',
    },
    {
      title: 'Verified',
      key: 'verified',
    },
    {
        title: 'Action',
        key: 'action',
    },
  ];
};

const UserTable = ({ updateTotalUserCount, filter, searchError, searchInput }) => {

    const dispatch = useModalDispatch();
    const queryClient = useQueryClient();

    const { successAlert } = useAlertDispatch();

    const [ offset, setOffset ] = useState(0);
    const [ limit, setLimit ] = useState(10);
    const [ count, setCount ] = useState(0);
    const [ users, setUsers ] = useState([]);
    const [ isLimit, setIsLimit ] = useState(false);
    const [ usersToShow, setUsersToShow ] = useState([]);

    const { isLoading, data, fetchNextPage, refetch, isFetching, error } = useInfiniteQuery(['users', offset, limit], () => getData(`/user?offset=${offset}&limit=${limit}&sort=latest&details=true`));

    useEffect(() => {
      if (data && data.pages[0].count > 0 && data.pages[0].count !== count) setCount(data.pages[0].count);
      if (data) {
        setUsers([...users, ...data.pages[0].data]);
        setUsersToShow([...users, ...data.pages[0].data]);
      }
    }, [data]);

    useEffect(() => {
      if (count > 0 && users.length === count) setIsLimit(true);
    }, [users])

    useEffect(() => {
      if (count > 0) updateTotalUserCount(count);
    }, [count]);

    useEffect(() => {
      if (offset < count) fetchNextPage({offset});
    }, [offset]);

    useEffect(() => {
      if (filter && filter.length > 0) {
        setUsersToShow([ ...filter ]);
      } else {
        setUsersToShow([ ...users ]);
      }
    }, [filter]);

    const handleVerificationToggle = (user) => {
      if (user.isVerified) {
        dispatch({ type: 'UNVERIFY_USER', value: user })
      } else dispatch({ type: 'VERIFY_USER', value: user });
    }

    const handleUserBan = (user) => {
      dispatch({ type: 'BAN_USER', value: user });
    }

    const getMoreUsers = () => {
      setOffset(offset + limit);
    }

    const copyToClipboard = (item) => {
      navigator.clipboard.writeText(item);
      successAlert("Successfully copied address to clipboard");
    }

    return (
        <YMTableDesktop cols={tableConstants()} paginated={false} infinite={true} showMore={getMoreUsers} isLimit={isLimit} fetching={isFetching} >
            {
              (users && users.length > 0)
                ? usersToShow.map((user, index) => (
                  <TableRow key={index} hover>
                    <TableCell>
                      <UserInfoCellComponent imageURL={user.avatar?.urlOriginal} username={user.username.toLowerCase()} displayName={user.displayName} isBanned={user.isBanned} />
                    </TableCell>
                    <TableCell>
                      {user.email?.toLowerCase()} <br/>
                      {user.ethAddress?.toLowerCase()}
                      {
                      (user.ethAddress)
                      ? 
                        <Tooltip title="User Lens Address, click to copy">
                          <IconButton variant="contained" color="primary" size="small" onClick={() => copyToClipboard(user.ethAddress)}>
                            <ContentCopy fontSize="8"/>
                          </IconButton>
                        </Tooltip>
                      : ""
                    }
                    </TableCell>
                    <TableCell>
                      <strong>{user.nPosts}</strong>
                    </TableCell>
                    <TableCell>
                      {user.country}
                    </TableCell>
                    <TableCell>
                      { moment(user.createdAt).format('DD/MM/YYYY') } <br/>
                      { moment(user.createdAt).format('HH:MM:SS')}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={(user.isVerified) ? user.isVerified : false}
                        onChange={() => handleVerificationToggle(user)}
                        color="primary"
                        name="verificationSwitch"
                      />
                    </TableCell>
                    <TableCell>
                      <Box display="flex" width="100px" justifyContent="space-between">
                        <Tooltip title="View YouMeme Profile">
                          <IconButton variant="contained" color="primary" size="large" href={`${process.env.REACT_APP_YOUMEME_FRONTEND_URL}/profile/${user.username.toLowerCase()}`} target="_blank"><Visibility /></IconButton>
                        </Tooltip>
                        <Tooltip title={(user.isBanned) ? "User Banned" : "Ban User"}>
                          <span>
                            <IconButton variant="outlined"  color="warning" size="large" disabled={user.isBanned} onClick={(e) => handleUserBan(user)}><Block /></IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Delete User">
                          <IconButton variant="contained" color="error" size="large" onClick={() => dispatch({ type: 'DELETE_USER', value: user })}><DeleteForever /></IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
                : <TableRow>
                    <TableCell colSpan={tableConstants().length} >
                      {
                        (isLoading)
                          ? <Loading />
                          : <NoDataFound error={error || searchError} />
                      }
                    </TableCell>
                  </TableRow>
            }
        </YMTableDesktop>
    )
}

UserTable.propTypes = {
  updateTotalUserCount: PropTypes.func,
  filter: PropTypes.array,
  searchError: PropTypes.object,
}

export default UserTable;
