import React from 'react';
import useContextFactory from './context.util';
import { authActions } from 'src/api/auth';
import { parseJWT } from 'src/utils/parseJwt';

var UserAuthStateContext = React.createContext();
var UserAuthDispatchContext = React.createContext();

// Check if user is persisted in local storage
const isUser = JSON.parse(localStorage.getItem('user'));

let userAuthInitState = {
    loginError: null,
    loginMessage: null,
    userLoggedIn: false,
    user: {},
    sessionToken: null,
    accessToken: null,
    sessionExpiry: null,
}

if (isUser) {
    userAuthInitState = {
        ...userAuthInitState,
        user: isUser.user,
        sessionToken: isUser.sessionToken,
        accessToken: isUser.accessToken,
        sessionExpiry: isUser.sessionExpiry,
        userLoggedIn: true,
        loginError: null,
        loginMessage: null,
    }
}

const userAuthReducer = (state, action) => {
    switch(action.type) {
        case 'LOGIN_INITIATED':
            return {
                ...state,
                loginError: null,
                loginMessage: action.value
            }
        case 'SET_USER_LOGIN_ERROR':
            return {
                ...state,
                loginError: action.value,
                loginMessage: null,
                userLoggedIn: false,
                user: {},
                sessionToken: null,
                accessToken: null,
                sessionExpiry: null,
            }
        case 'USER_LOGGED_IN':
            return {
                ...state,
                loginError: null,
                loginMessage: 'Successfully authenticated.',
                userLoggedIn: true,
                user: action.value.user,
                sessionToken: action.value.sessionToken,
                accessToken: action.value.accessToken,
                sessionExpiry: parseJWT(action.value.sessionToken).exp * 1000,
            }
        case 'USER_LOGOUT':
            return {
                ...state,
                userLoggedIn: false,
                loginError: null,
                loginMessage: null,
                user: {},
                sessionToken: null,
                accessToken: null,
                sessionExpiry: null,
            }
        case 'TOKENS_REFRESHED':
            return {
                ...state,
                sessionToken: action.value.sessionToken,
                accessToken: action.value.actionToken,
                sessionExpiry: parseJWT(action.value.sessionToken).exp * 1000,
                loginMessage: action.value.message,
                loginError: null,
            }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

const UserAuthProvider = ({ children }) => {
    var [state, dispatch] = React.useReducer(userAuthReducer, userAuthInitState);

    return (
        <UserAuthStateContext.Provider value={state}>
            <UserAuthDispatchContext.Provider value={dispatch}>
                { children }
            </UserAuthDispatchContext.Provider>
        </UserAuthStateContext.Provider>
    );
}

const useAuthState = () => useContextFactory(UserAuthStateContext);
const useAuthDispatch = () => {
    const dispatch = useContextFactory(UserAuthDispatchContext);
    const { logIn, verifyLogin, handleLoginError, logOut, refreshToken } = authActions(dispatch);
    return { dispatch, logIn, verifyLogin, handleLoginError, logOut, refreshToken };
};

export {
    UserAuthProvider,
    useAuthState,
    useAuthDispatch,
}