import { CheckCircleOutline } from '@mui/icons-material';
import { Box, Avatar, Typography, Chip, Tooltip } from '@mui/material';

const EventDetail = (props) => {
    return (
        <Box
            sx={{
            alignItems: 'center',
            display: 'flex'
            }}
        >
            <Avatar
            src={props.image}
            sx={{ mr: 2 }}
            />
            <Typography
            color="textPrimary"
            variant="body1"
            >
                <a href={props.url} target="_blank">{props.name}</a><br />
                {
                    (props.hasWinners)
                     ? 
                        <Tooltip title="Winners have been selected">
                            <span>
                                <Chip label="Finished" color='success' size='small' variant='outlined' icon={<CheckCircleOutline />}/>
                            </span>
                        </Tooltip>
                     : ''
                }
            </Typography>
        </Box>
    );
}

export default EventDetail;